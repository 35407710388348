import React from "react";
import { Route, Switch } from "react-router";
import { Redirect } from "react-router-dom";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";

import "./App.css";
import "./burgerMenu.css";

import AppContextComponent from "./AppContext";

import api from "./services/Api";
import { getUser } from "./helpers/Utilities";

import AuthorizedRoute from "./components/AuthorizedRoute";

import Home from "./components/Home";
import Layout from "./components/Layout";
import Placeholder from "./components/Placeholder";
import Test from "./components/Test";

import Login from "./components/login/Login";
import Logout from "./components/login/Logout";
import { ResetPassword } from "./components/login/ResetPassword";

import Dashboard from "./components/dashboards/Dashboard";

import TypeMap from "./components/maps/TypeMap/TypeMap";
import PerformanceMap from "./components/maps/PerformanceMap/PerformanceMap";
import FrequencyMap from "./components/maps/FrequencyMap/FrequencyMap";

import DashboardSetupHelp from "./components/help/DashboardSetupHelp";
import About from "./components/help/About";

import DashboardSettings from "./components/settings/DashboardSettings";
import JurisdictionSettings from "./components/settings/JurisdictionSettings";
import DataSettings from "./components/settings/DataSettings";
import UsersSettings from "./components/settings/UsersSettings";
import UserSettings from "./components/settings/UserSettings";
import BasicChart from "./components/charts/BasicChart";
import AdvancedChart from "./components/charts/AdvancedChart";
import SimpleReportPage from "./components/reports/SimpleReportPage";
import FirstArrivingHome from "./components/FirstArrivingHome";

function App() {
  const user = getUser();

  if (user) {
    // Set the auth header for axios.
    // NOTE: This is necessary, because if the user is logged in, but they refresh the page,
    // then the previously-set default authorization header will no longer be set in axios.
    // Doing this here will make sure that the JWT is passed up to the server on all axios calls.
    // If the user isn't logged in at this point, then this won't be run and it will be set
    // when the user successfully logs in.
    api.setAuthorizationHeader(user.jwt);
  }

  library.add(faBars, faTimes);

  return (
    <Layout>
      <AppContextComponent>
        <Switch>
          <Route path="/login" component={Login} />
          <Route
            path="/firstArriving/:key/:dashboardId?"
            component={FirstArrivingHome}
          />
          <AuthorizedRoute
            path="/dashboard/:dashboardId"
            component={Dashboard}
          />
          <AuthorizedRoute
            path="/map/type/:encodedOptions"
            component={TypeMap}
          />
          <AuthorizedRoute path="/map/type" component={TypeMap} />
          <AuthorizedRoute
            path="/map/performance/:encodedOptions"
            component={PerformanceMap}
          />
          <AuthorizedRoute path="/map/performance" component={PerformanceMap} />
          <AuthorizedRoute
            path="/map/frequency/:encodedOptions"
            component={FrequencyMap}
          />
          <AuthorizedRoute path="/map/frequency" component={FrequencyMap} />
          <AuthorizedRoute path="/map/heatmap" component={TypeMap} />
          <AuthorizedRoute
            path="/chart/basic/:encodedOptions"
            component={BasicChart}
          />
          <AuthorizedRoute path="/chart/basic" component={BasicChart} />
          <AuthorizedRoute
            path="/chart/advanced/:encodedOptions"
            component={AdvancedChart}
          />
          <AuthorizedRoute path="/chart/advanced" component={AdvancedChart} />
          <AuthorizedRoute path="/table/:chartType" component={Placeholder} />
          <AuthorizedRoute
            path="/reports/:reportName/:encodedOptions"
            component={Placeholder}
          />
          <AuthorizedRoute
            path="/reports/:reportName"
            component={SimpleReportPage}
          />
          <AuthorizedRoute
            path="/help/creatingDashboards"
            component={DashboardSetupHelp}
          />
          <AuthorizedRoute path="/help/about" component={About} />
          <AuthorizedRoute
            path="/settings/jurisdiction"
            component={JurisdictionSettings}
          />
          <AuthorizedRoute path="/settings/data" component={DataSettings} />
          <AuthorizedRoute
            path="/settings/dashboards"
            component={DashboardSettings}
          />
          <AuthorizedRoute path="/logout" component={Logout} />
          <AuthorizedRoute path="/settings/users" component={UsersSettings} />
          <AuthorizedRoute
            path="/settings/user/:userId"
            component={UserSettings}
          />
          <AuthorizedRoute path="/settings/user" component={UserSettings} />
          <AuthorizedRoute
            path="/settings/resetPassword"
            component={ResetPassword}
          />
          <AuthorizedRoute path="/test" component={Test} />
          <AuthorizedRoute exact path="/" component={Home} />
          <Route render={() => <Redirect to="/" />} />
        </Switch>
      </AppContextComponent>
    </Layout>
  );
}

export default App;
