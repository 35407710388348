// React
import React, { Component } from "react";
import PropTypes from "prop-types";

// Material-UI
import {
  withStyles,
  withTheme,
  CircularProgress,
  Typography,
  Drawer
} from "@material-ui/core";

// Redux
import { connect } from "react-redux";
import { getSettingsOpen } from "../../redux/selectors";
import {
  toggleSettingsOpen,
  closeSettings,
  setSettingsEnabled
} from "../../redux/actions";

// C3
import c3 from "c3";
import "c3/c3.css";

// Other
import uuidv4 from "uuid/v4";

// Firewatch
import { getAvailableWindowHeight } from "../../helpers/Utilities";
import { DataContext } from "../DataContext";
import { ComponentContext } from "../DashboardComponent";

import { NewTabButton, ShowDetailsButton } from "../controls/MenuControls";

const drawerHeight = 340;

const styles = theme => ({
  spinnerContainer: {
    display: "flex",
    justifyContent: "center",
    position: "relative",
    top: "calc(50% - 32px)"
  },
  chartTitle: {
    textAlign: "center",
    fontSize: "1.75rem"
  },
  chartDate: {
    textAlign: "center",
    fontSize: "1rem"
  },
  chartContainer: {
    display: "flex",
    flex: "1 1 auto",
    flexDirection: "column"
  },
  chart: {
    flex: "1 1 auto"
    //    paddingTop: "10px",
    //    paddingBottom: "10px"
  },
  topGutter: {
    flex: "0 1 auto",
    paddingTop: "10px"
  },
  bottomGutter: {
    flex: "0 1 auto"
    //    paddingBottom: "10px"
  },
  optionsButtonsOuterContainer: {
    width: "32px",
    top: "0px",
    right: "32px",
    position: "absolute"
  },
  optionsButtonsInnerContainer: {
    top: "0.5em",
    left: "0.5em",
    position: "absolute"
  },
  drawer: {
    width: "100%",
    flexShrink: 0
  },
  drawerPaper: {
    height: drawerHeight,
    width: "100%",
    zIndex: 1000
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-start"
  },
  content: {
    flexGrow: 1,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    marginTop: 0
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginBottom: 0
  }
});

export const chartTypes = [
  "bar",
  "step",
  "line",
  "spline",
  "area",
  "area-spline",
  "stacked-bar",
  "stacked-area",
  "stacked-area-spline",
  "pie",
  "donut",
  "gauge"
];

export const groupedChartTypes = [
  "stacked-bar",
  "stacked-area",
  "stacked-area-spline"
];

export const textPositions = { top: "Top", bottom: "Bottom" };

export default class BaseChart extends Component {
  render() {
    return (
      <DataContext.Consumer>
        {({ ...dataSettings }) => (
          <ComponentContext.Consumer>
            {({ ...settings }) => (
              <BaseChartContainer
                {...settings}
                {...dataSettings}
                {...this.props}
              />
            )}
          </ComponentContext.Consumer>
        )}
      </DataContext.Consumer>
    );
  }
}

class BaseChartComponent extends Component {
  constructor(props) {
    super(props);

    this.chartId = "c3-chart-" + uuidv4();
    this.titleRef = React.createRef();
    this.dateRef = React.createRef();
  }

  state = {};

  componentDidMount = () => {
    window.addEventListener("resize", this.resizeComponents);
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (
      this.props.type !== prevProps.type ||
      this.props.columns !== prevProps.columns ||
      this.props.xAxisCategories !== prevProps.xAxisCategories ||
      this.props.xAxisLines !== prevProps.xAxisLines ||
      this.props.yAxisLines !== prevProps.yAxisLines ||
      this.props.yAxisText !== prevProps.yAxisText ||
      this.props.colors !== prevProps.colors ||
      this.props.yAxisMin !== prevProps.yAxisMin ||
      this.props.yAxisMax !== prevProps.yAxisMax ||
      this.props.gaugeMax !== prevProps.gaugeMax ||
      this.props.zoom !== prevProps.zoom
    ) {
      this.renderC3Chart();
    }

    if (
      this.props.drawTitle !== prevProps.drawTitle ||
      this.props.drawDates !== prevProps.drawDates ||
      (this.props.title != "" && prevProps.title == "") ||
      (this.props.title == "" && prevProps.title != "")
    ) {
      document.getElementById(this.chartId).style.maxHeight = "unset";
      this.renderC3Chart();
    }

    if (
      this.props.settingsOpen !== prevProps.settingsOpen ||
      this.props.height !== prevProps.height
    ) {
      document.getElementById(this.chartId).style.maxHeight = "unset";
      document.getElementById(this.chartId).style.maxWidth = "unset";
      this.resizeComponents();
    }
  };

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.resizeComponents);
  };

  resizeComponents = () => {
    if (this.state.c3chart) {
      this.state.c3chart.resize({
        height: this.getChartHeight(),
        width: undefined
      });
    }
  };

  toggleDetails = () => {
    this.setState({ detailsOpen: !this.state.detailsOpen });
    var timer = setTimeout(this.resizeComponents, 200);
  };

  getChartHeight = () => {
    var controlHeight =
      this.props.height - 24 || getAvailableWindowHeight() - 20; // I hate magic numbers, but we're going with this for now
    if (this.titleRef.current)
      controlHeight -= this.titleRef.current.offsetHeight;

    if (this.dateRef.current)
      controlHeight -= this.dateRef.current.offsetHeight;

    if (this.state.detailsOpen) {
      controlHeight -= drawerHeight;
    }

    return controlHeight;
  };

  renderC3Chart = () => {
    if (!this.props.columns) {
      return;
    }

    if (groupedChartTypes.includes(this.props.type)) {
      this.renderC3StackedChart();
      return;
    } else if (this.props.type == "pie") {
      this.renderC3PieChart();
      return;
    } else if (this.props.type == "donut") {
      this.renderC3DonutChart();
      return;
    } else if (this.props.type == "gauge") {
      this.renderC3GaugeChart();
      return;
    }

    let yAxisMax = this.props.yAxisMax
      ? Number.parseFloat(this.props.yAxisMax)
      : undefined;
    let yAxisMin = this.props.yAxisMin
      ? Number.parseFloat(this.props.yAxisMin)
      : 0;

    // console.log(this.props.xAxisCategories);
    // if (this.props.aggregation){
    //   console.log(this.props.aggregation);
    // }

    let c3chart = c3.generate({
      bindto: `#${this.chartId}`,
      data: {
        columns: this.props.columns,
        type: this.props.type,
        colors: this.props.colors
      },
      zoom: {
        enabled: this.props.zoom || false
      },
      legend: {
        position: "right"
      },
      axis: {
        x: {
          type: "category",
          categories: this.props.xAxisCategories,
          height: 55,
          label: this.props.xAxisLabel,
          position: "outer-center"
        },
        y: {
          max: yAxisMax,
          min: yAxisMin,
          padding: { top: 50, bottom: 0 },
          label: {
            text: this.props.yAxisText,
            position: "outer-middle"
          }
        }
      },
      grid: {
        x: {
          lines: this.props.xAxisLines
        },
        y: {
          lines: this.props.yAxisLines
        }
      },
      size: {
        height: this.getChartHeight()
      },
      padding: {
        top: 10,
        bottom: -20
      }
    });
    this.setState({ c3chart: c3chart });
  };

  renderC3StackedChart = () => {
    var columns = this.props.columns || [];
    const groups = [];
    for (var i = 0; i < columns.length; i++) {
      groups.push(columns[i][0]);
    }

    var chartType = this.props.type.substring(8);

    let yAxisMax =
      this.props.yAxisMax && Number.parseFloat(this.props.yAxisMax);
    let yAxisMin =
      this.props.yAxisMin && Number.parseFloat(this.props.yAxisMin);

    let c3chart = c3.generate({
      bindto: `#${this.chartId}`,
      data: {
        columns: columns,
        type: chartType,
        groups: [groups],
        colors: this.props.colors
      },
      zoom: {
        enabled: this.props.zoom || false
      },
      legend: {
        position: "right"
      },
      axis: {
        x: {
          type: "category",
          categories: this.props.xAxisCategories,
          height: 50,
          label: {
            text: this.props.xAxisLabel,
            position: "outer-center"
          }
        },
        y: {
          max: yAxisMax,
          min: yAxisMin,
          padding: { top: 50, bottom: 0 },
          label: {
            text: this.props.yAxisText,
            position: "outer-middle"
          }
        }
      },
      grid: {
        x: {
          lines: this.props.xAxisLines
        },
        y: {
          lines: this.props.yAxisLines
        }
      },
      size: {
        height: this.getChartHeight()
      },
      padding: {
        top: 10,
        bottom: -20
      }
    });
    this.setState({ c3chart: c3chart });
  };

  renderC3PieChart = () => {
    let c3chart = c3.generate({
      bindto: `#${this.chartId}`,
      data: {
        columns: this.props.columns,
        type: this.props.type,
        colors: this.props.colors
      },
      zoom: {
        enabled: this.props.zoom || false
      },
      pie: {
        label: {
          format: function(value, ratio, id) {
            return `${Number(value).toLocaleString(undefined, {
              style: "decimal",
              maximumFractionDigits: 2
            })} - ${Number(ratio).toLocaleString(undefined, {
              style: "percent",
              minimumFractionDigits: 1,
              maximumFractionDigits: 1
            })}`;
          }
        }
      },
      legend: {
        position: "right"
      },
      tooltip: {
        format: {
          title: function(d) {
            return d;
          },
          value: function(value, ratio, id) {
            return `${Number(value).toLocaleString(undefined, {
              style: "decimal",
              maximumFractionDigits: 2
            })} - ${Number(ratio).toLocaleString(undefined, {
              style: "percent",
              minimumFractionDigits: 1,
              maximumFractionDigits: 1
            })}`;
          }
        }
      },
      axis: {
        x: {
          type: "category",
          categories: this.props.xAxisCategories,
          height: 50
        },
        y: {
          label: {
            text: this.props.yAxisText,
            position: "outer-middle"
          }
        }
      },
      size: {
        height: this.getChartHeight()
      },
      padding: {
        top: 10,
        bottom: -20
      }
    });
    this.setState({ c3chart: c3chart });
  };

  renderC3DonutChart = () => {
    let c3chart = c3.generate({
      bindto: `#${this.chartId}`,
      data: {
        columns: this.props.columns,
        type: this.props.type,
        colors: this.props.colors
      },
      zoom: {
        enabled: this.props.zoom || false
      },
      donut: {
        label: {
          format: function(value, ratio, id) {
            return `${Number(value).toLocaleString(undefined, {
              style: "decimal",
              maximumFractionDigits: 2
            })} - ${Number(ratio).toLocaleString(undefined, {
              style: "percent",
              minimumFractionDigits: 1,
              maximumFractionDigits: 1
            })}`;
          }
        }
      },
      legend: {
        position: "right"
      },
      tooltip: {
        format: {
          title: function(d) {
            return d;
          },
          value: function(value, ratio, id) {
            return `${Number(value).toLocaleString(undefined, {
              style: "decimal",
              maximumFractionDigits: 2
            })} - ${Number(ratio).toLocaleString(undefined, {
              style: "percent",
              minimumFractionDigits: 1,
              maximumFractionDigits: 1
            })}`;
          }
        }
      },
      axis: {
        x: {
          type: "category",
          categories: this.props.xAxisCategories,
          height: 50
        },
        y: {
          label: {
            text: this.props.yAxisText,
            position: "outer-middle"
          }
        }
      },
      size: {
        height: this.getChartHeight()
      },
      padding: {
        top: 10,
        bottom: -20
      }
    });
    this.setState({ c3chart: c3chart });
  };

  renderC3GaugeChart = () => {
    let maxValue =
      (this.props.gaugeMax &&
        this.props.gaugeMax != "" &&
        Number.parseFloat(this.props.gaugeMax)) ||
      100;

    let c3chart = c3.generate({
      bindto: `#${this.chartId}`,
      data: {
        columns: this.props.columns,
        type: this.props.type,
        colors: this.props.colors
      },
      gauge: {
        max: maxValue
      },
      zoom: {
        enabled: this.props.zoom || false
      },
      legend: {
        position: "right"
      },
      size: {
        height: this.getChartHeight()
      },
      padding: {
        top: 10,
        bottom: -20
      }
    });
    this.setState({ c3chart: c3chart });
  };

  render = () => {
    const {
      classes,
      dashboardMode,
      height,
      type,
      drawTitle,
      title,
      titlePosition,
      drawDates,
      dateText,
      datePosition,
      columns,
      xAxisCategories,
      xAxisLines,
      yAxisText,
      yAxisLines,
      showDetailsButton,
      showNewTabButton
    } = this.props;

    return (
      <div id={this.props.id}>
        {(this.props.loading && (
          <div className={classes.spinnerContainer}>
            <CircularProgress color="secondary" size={64}></CircularProgress>
          </div>
        )) || (
          <div id="chart" className={classes.chartContainer}>
            <div className={classes.topGutter}>
              {drawTitle && titlePosition == "top" && (
                <Typography
                  ref={this.titleRef}
                  variant="h4"
                  className={classes.chartTitle}
                >
                  {title}
                </Typography>
              )}
              {drawDates && datePosition == "top" && (
                <Typography
                  ref={this.dateRef}
                  variant="h5"
                  className={classes.chartDate}
                >
                  {dateText}
                </Typography>
              )}
            </div>
            <div style={{ position: "relative" }}>
              <div id={this.chartId} className={classes.chart} />
              <div className={classes.optionsButtonsOuterContainer}>
                <div className={classes.optionsButtonsInnerContainer}>
                  <NewTabButton
                    in={showNewTabButton}
                    onClick={this.props.newTabFunction}
                  />
                  <ShowDetailsButton
                    in={showDetailsButton}
                    pressed={this.state.detailsOpen}
                    onClick={this.toggleDetails}
                  />
                </div>
              </div>
            </div>
            <div className={classes.bottomGutter}>
              {drawTitle && titlePosition == "bottom" && (
                <Typography
                  ref={this.titleRef}
                  variant="h3"
                  className={classes.chartTitle}
                >
                  {title}
                </Typography>
              )}
              {drawDates && datePosition == "bottom" && (
                <Typography
                  ref={this.dateRef}
                  variant="h5"
                  className={classes.chartDate}
                >
                  {dateText}
                </Typography>
              )}
            </div>
            <Drawer
              className={classes.drawer}
              variant="persistent"
              anchor="bottom"
              open={this.state.detailsOpen}
              classes={{
                paper: classes.drawerPaper
              }}
            >
              {this.state.detailsOpen && this.props.drawerContents}
            </Drawer>
          </div>
        )}
      </div>
    );
  };
}

BaseChartComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  const settingsOpen = getSettingsOpen(state);
  return { settingsOpen };
};

const mapDispatchToProps = {
  toggleSettingsOpen,
  closeSettings,
  setSettingsEnabled
};

const BaseChartContainer = connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true
})(withStyles(styles)(withTheme(BaseChartComponent)));
