import axios from "axios";
import { getUser as getUserUtility } from "../helpers/Utilities";
const API_BASE_URL =
  process.env.REACT_APP_ENV == "development"
    ? "https://localhost:5001/api"
    : "/api";

const getStandardQueryString = (start, end, callIds, code, units, priority) => {
  let queryString = "";
  if (start) queryString += `&start=${start}`;
  if (end) queryString += `&end=${end}`;
  if (callIds) queryString += `&callIds=${callIds}`;
  if (code) queryString += `&code=${code}`;
  if (units) queryString += `&units=${units}`;
  if (priority) queryString += `&priority=${priority}`;

  return queryString;
};

export default class Api {
  static setAuthorizationHeader(jwt) {
    axios.defaults.headers.common["Authorization"] = "Bearer " + jwt;

    // Let's also setup the default response for when a 401 is received
    axios.interceptors.response.use(undefined, function(error) {
      if (error.response.status === 401) {
        // TODO: Instead of just navigating back to the login page,
        // could there be a way to show a modal to the user so they can
        // re-authenticate right there and then continue on with what
        // they were doing. This would be a bit of work, so for now
        // simply redirecting to the login page will suffice.
        window.location.href = "/login";
      }
    });
  }

  static async authenticate(email, password) {
    try {
      let response = await axios.post(`${API_BASE_URL}/User/Authenticate`, {
        email,
        password
      });
      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  static async registerUser(user) {
    try {
      let response = await axios.post(`${API_BASE_URL}/User/Register`, user);
      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  static async getUsers() {
    try {
      let response = await axios.get(`${API_BASE_URL}/User/All`);
      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  static async getUser(userId) {
    try {
      let response = await axios.get(`${API_BASE_URL}/User/${userId}`);
      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  static async getCurrentUser() {
    try {
      let response = await axios.get(`${API_BASE_URL}/User`);
      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  static async saveUser(user) {
    try {
      let response = await axios.put(`${API_BASE_URL}/User`, user);
      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  static async deleteUser(userId) {
    try {
      let response = await axios.delete(`${API_BASE_URL}/User/${userId}`);
      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  static async resetPassword(oldPassword, newPassword) {
    try {
      var info = { oldPassword, newPassword };
      let response = await axios.post(
        `${API_BASE_URL}/User/ResetPassword`,
        info
      );
      if (!response || response.status != 200) {
        return false;
      }
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  static async getCollection(collectionId) {
    try {
      let response = await axios.get(
        `${API_BASE_URL}/LevrumExampleEntityFramework/GetCollection/${collectionId}`
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  static async getAllUnits() {
    try {
      let response = await axios.get(`${API_BASE_URL}/data/units`);
      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  static async getMapSettings() {
    try {
      let response = await axios.get(`${API_BASE_URL}/settings/map`);
      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  static async getDataSettings() {
    try {
      let response = await axios.get(`${API_BASE_URL}/settings/data`);
      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  static async testDataSettings(settings) {
    try {
      const response = await axios.put(
        `${API_BASE_URL}/settings/testData`,
        settings
      );
      return response;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  static async updateDataSettings(settings) {
    try {
      const response = await axios.put(
        `${API_BASE_URL}/settings/data`,
        settings
      );
      return response;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  static async getJurisdictionSettings() {
    try {
      const response = await axios.get(`${API_BASE_URL}/settings/jurisdiction`);
      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  static async saveJurisdictionSettings(settings) {
    try {
      const response = await axios.put(
        `${API_BASE_URL}/settings/jurisdiction`,
        settings
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  static async getRegions() {
    try {
      let response = await axios.get(`${API_BASE_URL}/settings/regions`);
      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  static async addRegion(name, geoJson, splitShapes) {
    try {
      let region = { Name: name, GeoJSON: geoJson, SplitShapes: splitShapes };
      let response = await axios.put(
        `${API_BASE_URL}/settings/addRegion`,
        region
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  static async updateRegion(id, name, geoJson, splitShapes) {
    try {
      let region = { Id: id, Name: name, GeoJSON: geoJson };
      let response = await axios.put(
        `${API_BASE_URL}/settings/updateRegion`,
        region
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  static async deleteRegion(id) {
    try {
      let response = await axios.delete(
        `${API_BASE_URL}/settings/region/${id}`
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  static async uploadFile(
    url,
    formData,
    fileName,
    contentType,
    progressListener,
    loadListener,
    errorListener,
    cancelTokenSource
  ) {
    try {
      let response = await axios.post(url, formData, {
        headers: { "Content-Type": contentType, Accept: contentType },
        onUploadProgress: progressListener,
        cancelToken: cancelTokenSource.token
      });

      if (loadListener) loadListener();

      return response;
    } catch (error) {
      if (errorListener) errorListener(error);

      return null;
    }
  }

  static async uploadData(
    formData,
    fileName,
    progressListener,
    loadListener,
    errorListener,
    cancelTokenSource
  ) {
    const url = `${API_BASE_URL}/settings/uploadData`;
    Api.uploadFile(
      url,
      formData,
      fileName,
      "multipart/form-data",
      progressListener,
      loadListener,
      errorListener,
      cancelTokenSource
    );
  }

  static async uploadDataMap(
    formData,
    fileName,
    progressListener,
    loadListener,
    errorListener,
    cancelTokenSource
  ) {
    const url = `${API_BASE_URL}/settings/uploadDataMap`;
    console.log(formData);
    console.log(fileName);
    Api.uploadFile(
      url,
      formData,
      fileName,
      "application/json",
      progressListener,
      loadListener,
      errorListener,
      cancelTokenSource
    );
  }

  static async getIncidents(start, end, callIds, code, units, priority) {
    try {
      let queryString = getStandardQueryString(
        start,
        end,
        callIds,
        code,
        units,
        priority
      );
      let response = await axios.get(
        `${API_BASE_URL}/data/incidents?${queryString}`
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  static async getResponses(start, end, callIds, code, units, priority) {
    try {
      let queryString = getStandardQueryString(
        start,
        end,
        callIds,
        code,
        units,
        priority
      );

      let response = await axios.get(
        `${API_BASE_URL}/data/responses?${queryString}`
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  static async getIncidentsWithResponses(
    start,
    end,
    callIds,
    code,
    units,
    priority
  ) {
    try {
      let queryString = getStandardQueryString(
        start,
        end,
        callIds,
        code,
        units,
        priority
      );

      let response = await axios.get(
        `${API_BASE_URL}/data/incidentsWithResponses?${queryString}`
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  static async getIncidentsWithResponsesKml(
    start,
    end,
    callIds,
    code,
    units,
    priority
  ) {
    try {
      let queryString = getStandardQueryString(
        start,
        end,
        callIds,
        code,
        units,
        priority
      );

      let response = await axios.get(
        `${API_BASE_URL}/data/incidentsWithResponsesKml?${queryString}`
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  static async getIncidentsByLocation(start, end) {
    try {
      let queryString = `&start=${start}&end=${end}`;

      let response = await axios.get(
        `${API_BASE_URL}/data/incidentsByLocation?${queryString}`
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  static async getRegionsWithIncidentIDs(start, end) {
    try {
      let queryString = `&start=${start}&end=${end}`;

      let response = await axios.get(
        `${API_BASE_URL}/data/regionsWithIncidents?${queryString}`
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  static async getDateRange() {
    try {
      let response = await axios.get(`${API_BASE_URL}/data/daterange`);
      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  static async getMetricList() {
    try {
      let response = await axios.get(`${API_BASE_URL}/calculation/metricList`);
      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  static async getCalculationList() {
    try {
      let response = await axios.get(
        `${API_BASE_URL}/calculation/calculationList`
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  static async getCalculationParameters(calculation) {
    try {
      let response = await axios.get(
        `${API_BASE_URL}/calculation/calculationParameters?calculation=${encodeURIComponent(
          calculation
        )}`
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  static async getAggregationList() {
    try {
      let response = await axios.get(
        `${API_BASE_URL}/calculation/aggregationList`
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  static async getAggregationCategories(
    aggregation,
    start,
    end,
    callIds,
    priority
  ) {
    try {
      let queryString = "";
      if (aggregation) queryString += `&aggregation=${aggregation}`;
      if (start) queryString += `&start=${start}`;
      if (end) queryString += `&end=${end}`;
      if (callIds) queryString += `&callIds=${callIds}`;
      if (priority) queryString += `&priority=${priority}`;

      let response = await axios.get(
        `${API_BASE_URL}/calculation/aggregationCategories?${queryString}`
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  static async getChartData(
    start,
    end,
    metric,
    calculation,
    aggregation,
    category,
    categories,
    group,
    calculationParameters,
    filters,
    regionIds
  ) {
    try {
      let request = {
        start: start,
        end: end,
        metric: metric,
        calculation: calculation,
        aggregation: aggregation,
        category: category,
        categories: categories,
        group: group,
        calculationParameters: calculationParameters,
        filters: filters,
        regionIds: regionIds
      };
      let queryString = "";

      let response = await axios.put(
        `${API_BASE_URL}/calculation/metric`,
        request
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  static async getChartIncidents(
    start,
    end,
    metric,
    calculation,
    aggregation,
    category,
    categories,
    group,
    calculationParameters,
    filters,
    regionIds
  ) {
    try {
      let request = {
        start: start,
        end: end,
        metric: metric,
        calculation: calculation,
        aggregation: aggregation,
        category: category,
        categories: categories,
        group: group,
        calculationParameters: calculationParameters,
        filters: filters,
        regionIds: regionIds
      };
      let queryString = "";

      let response = await axios.put(
        `${API_BASE_URL}/calculation/metricIncidents`,
        request
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  static async getTableData(startDate, endDate, units) {
    let queryString = "";
    if (startDate) queryString += `&start=${startDate}`;
    if (endDate) queryString += `&end=${endDate}`;
    if (units) queryString += `&units=${units}`;

    try {
      let response = await axios.get(
        `${API_BASE_URL}/data/incidentsWithResponses?${queryString}`
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  static async getDashboard(dashboardId) {
    try {
      let response = await axios.get(
        `${API_BASE_URL}/dashboard/${dashboardId}`
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  static async getHomeDashboard() {
    try {
      let response = await axios.get(`${API_BASE_URL}/dashboard/home`);
      if (response) return response.data;
      else return null;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  static async setHomeDashboard(dashboardId) {
    try {
      let response = await axios.put(
        `${API_BASE_URL}/dashboard/SetHomeDashboardForUser/${dashboardId}`
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  static async getAllDashboards() {
    try {
      let response = await axios.get(`${API_BASE_URL}/dashboard/all`);
      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  // Adds a dashboard and then returns the added dashboard with the ID set
  static async addDashboard(dashboard, isJurisdiction) {
    try {
      if (isJurisdiction) {
        // Get the current user object
        let user = getUserUtility();

        // Add the jurisdictionId property to the dashboard object
        dashboard.jurisdictionId = user.jurisdictionId;
      }

      const response = await axios.post(`${API_BASE_URL}/dashboard`, dashboard);

      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  // Updates a dashboard and then returns the updated dashboard
  static async updateDashboard(dashboard) {
    try {
      const response = await axios.put(`${API_BASE_URL}/dashboard/`, dashboard);

      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  // Deletes a dashboard
  static async deleteDashboard(dashboardId) {
    try {
      const response = await axios.delete(
        `${API_BASE_URL}/dashboard/${dashboardId}`
      );

      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  static async addDashboardItem(dashboardItem) {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/dashboard/item`,
        dashboardItem
      );

      return response;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  static async updateDashboardItem(
    dashboardId,
    dashboardItemId,
    title,
    filters
  ) {
    try {
      var dashboardItem = {
        dashboardId: dashboardId,
        id: dashboardItemId,
        filters: filters,
        title: title
      };

      const response = await axios.post(
        `${API_BASE_URL}/dashboard/updateItem`,
        dashboardItem
      );

      return response;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  static async deleteDashboardItem(dashboardItemId) {
    try {
      const response = await axios.delete(
        `${API_BASE_URL}/dashboard/item/${dashboardItemId}`
      );
      return response;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  static async getFilterDateRange() {
    try {
      const response = await axios.get(`${API_BASE_URL}/data/daterange`);
      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  static async getFilterFields() {
    try {
      const response = await axios.get(`${API_BASE_URL}/data/filterFields`);
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  static async getFilterFieldValues(field, startDate, endDate) {
    try {
      if (!field) {
        return [];
      }
      let queryString = `fieldid=${field.id}&type=${field.type}`;
      if (startDate) queryString += `&start=${startDate}`;
      if (endDate) queryString += `&end=${endDate}`;

      const response = await axios.get(
        `${API_BASE_URL}/data/filterFieldValues?${queryString}`
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  static async getUnitDetails(start, end, callIds, code, units, priority) {
    try {
      let queryString = getStandardQueryString(
        start,
        end,
        callIds,
        code,
        units,
        priority
      );

      const response = await axios.get(
        `${API_BASE_URL}/reports/unitDetails?${queryString}`
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  static async getCallDetails(start, end, callIds, code, units, priority) {
    try {
      let queryString = getStandardQueryString(
        start,
        end,
        callIds,
        code,
        units,
        priority
      );

      const response = await axios.get(
        `${API_BASE_URL}/reports/callDetails?${queryString}`
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  static getUserName() {
    try {
      if (!localStorage || !localStorage.user) return "";

      var user = JSON.parse(localStorage.user);

      if (user.email && !user.firstName && !user.lastName) return user.email;

      if (user.firstName && user.lastName) {
        return `${user.firstName} ${user.lastName}`;
      } else {
        return user.firstName ? user.firstName : user.lastName;
      }
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  static getJurisdictionName() {
    try {
    } catch (error) {
      console.error(error);
    }

    return "Your Jurisdiction";
  }

  static logout() {
    try {
      localStorage.setItem("user", null);
      Api.setAuthorizationHeader("");
    } catch (error) {
      console.error(error);
    }
  }

  static async forceCadRefresh() {
    try {
      const response = await axios.get(`${API_BASE_URL}/settings/refreshCad`);
      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  static async validateReadyRoomKey(key) {
    try {
      const response = await axios.get(`${API_BASE_URL}/readyroom/${key}`);
      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }
}
