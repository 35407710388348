import React, { Component } from "react";
import PropTypes from "prop-types";

import {
  withStyles,
  withTheme,
  Collapse,
  CircularProgress,
  Paper,
  Popper,
  Drawer
} from "@material-ui/core";

import clsx from "clsx";

// Openlayers
import "ol";
import "ol/ol.css";
import { DEVICE_PIXEL_RATIO } from "ol/has.js";
import { easeOut } from "ol/easing.js";
import Feature from "ol/Feature.js";
import Map from "ol/Map.js";
import Overlay from "ol/Overlay.js";
import View from "ol/View.js";
import Point from "ol/geom/Point.js";
import {
  click,
  pointerMove,
  altKeyOnly,
  shiftKeyOnly
} from "ol/events/condition";
import { unByKey } from "ol/Observable";
import Select from "ol/interaction/Select";
import {
  Tile as TileLayer,
  Vector as VectorLayer,
  Heatmap as HeatmapLayer
} from "ol/layer.js";
import VectorSource from "ol/source/Vector.js";
import { Style, Circle, Fill, Stroke, RegularShape } from "ol/style.js";
import { fromLonLat, toLonLat } from "ol/proj.js";
import XYZ from "ol/source/XYZ.js";
import { defaults as defaultControls } from "ol/control.js";
import HexBin from "ol-ext/source/HexBin";
import GeoJSON from "ol/format/GeoJSON";
import { getVectorContext } from "ol/render";

// Firewatch
import { DataContext } from "../DataContext";
import { ComponentContext } from "../DashboardComponent";
import { hasTouch, getAvailableWindowHeight } from "../../helpers/Utilities";
import { heatmapGradientColors, getHexColor } from "../../helpers/Colors";
import {
  MapKey,
  LoadSpinner,
  ZoomInButton,
  ZoomOutButton,
  SatelliteButton,
  ResetButton,
  NewTabButton,
  ShowKeyButton,
  ShowDetailsButton
} from "./MapElements";
import { BaseTooltip } from "./BaseTooltip";

var geojson_validator = require("geojson-validation");

var ATTRIBUTION =
  '<div style="position: relative; bottom: 4px">' +
  "&#169; " +
  '<a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> ' +
  "contributors" +
  "</div>";

var MAPTILER_ATTRIBUTION =
  '<div style="position: relative; bottom: 4px">' +
  '<a href="https://www.maptiler.com/copyright/" target="_blank">© MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">© OpenStreetMap contributors</a>' +
  "</div>";

const defaultStroke = new Stroke({
  color: [64, 64, 64, 0.8],
  width: 1.5
});

const defaultStyle = new Style({
  image: new Circle({
    radius: 5,
    stroke: defaultStroke,
    fill: new Fill({
      color: [255, 102, 102, 0.5]
    })
  }),
  zIndex: 1
});

const defaultStyleFunction = feature => {
  feature.setStyle(defaultStyle);
};

const defaultTooltipFunction = (element, features) => {
  if (features && features.length === 1) {
    element.innerHTML = `1 feature at location`;
  } else {
    element.innerHTML = `${features.length} features at location`;
  }
};

const drawerHeight = 338;

const styles = theme => ({
  optionsButtonsOuterContainer: {
    width: "30px",
    position: "relative"
  },
  optionsButtonsInnerContainer: {
    top: "0.5em",
    left: "0.5em",
    position: "absolute"
  },
  optionsButtonsInnerContainerTouch: {
    top: "0.5em",
    left: "0.5em",
    position: "absolute"
  },
  descriptionContainer: {
    position: "absolute",
    bottom: "0em"
  },
  appBar: {
    transition: theme.transitions.create(["margin", "height"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    width: `calc(100% - ${drawerHeight}px)`,
    transition: theme.transitions.create(["margin", "height"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginBottom: drawerHeight
  },
  title: {
    flexGrow: 1
  },
  hide: {
    display: "none"
  },
  drawer: {
    width: "100%",
    flexShrink: 0
  },
  drawerPaper: {
    height: drawerHeight,
    width: "100%",
    zIndex: 1000
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-start"
  },
  content: {
    flexGrow: 1,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    marginTop: 0
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginBottom: 0
  }
});

export class MapPoint {
  longitude = 0.0;
  latitude = 0.0;
  value = 0.0;
  data = {};

  constructor(_longitude, _latitude, _value, _data) {
    this.longitude = _longitude;
    this.latitude = _latitude;
    this.value = _value;
    this.data = _data;
  }
}

export default class BaseMap extends Component {
  render() {
    return (
      <DataContext.Consumer>
        {({ ...dataSettings }) => (
          <ComponentContext.Consumer>
            {({ ...settings }) => (
              <BaseMapContainer
                {...settings}
                {...dataSettings}
                {...this.props}
              />
            )}
          </ComponentContext.Consumer>
        )}
      </DataContext.Consumer>
    );
  }
}

class BaseMapComponent extends Component {
  constructor(props) {
    super(props);
    this.mapRef = React.createRef();
    this.olPoints = {};
    this.updateZoomFunction = this.props.updateZoomFunction
      ? this.props.updateZoomFunction
      : this.updateBlurForZoom;

    this.tooltipAnchorRef = React.createRef();
  }

  state = {
    latestFeatures: [],
    tooltipFeatures: []
  };

  static defaultProps = {
    longitude: -123.2621,
    latitude: 44.5644,
    zoom: 16,
    styleFunction: defaultStyleFunction,
    tooltipFunction: defaultTooltipFunction,
    showZoomButtons: true,
    showSatelliteButton: true,
    showResetButton: true,
    showNewTabButton: true,
    showKeyButton: true,
    dashboardMode: false,
    height: "446px"
  };

  componentDidMount() {
    window.addEventListener("resize", this.resizeComponents);
    this.resizeComponents();
    this.setupMap();
  }

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.resizeComponents);
    this.props.setSettings({ hexBin: undefined });
    this.cleanupMapElements();
  };

  async componentDidUpdate(prevProps, prevState) {
    if (
      this.props.latitude !== prevProps.latitude ||
      this.props.longitude !== prevProps.longitude ||
      this.props.zoom !== prevProps.zoom ||
      this.props.id !== prevProps.id
    ) {
      this.resetMapView();
    }

    if (this.state.detailsOpen !== prevState.detailsOpen) {
      this.startAnimation();
    }

    if (this.props.showSatellite !== prevProps.showSatellite) {
      this.updateRasterLayer();
    }

    if (this.props.points !== prevProps.points) {
      this.setupPointVectorSource();
      if (!prevProps.points && this.props.drawPoints) {
        this.renderPoints();
      }
    }

    if (this.props.hexagonPoints !== prevProps.hexagonPoints) {
      this.setupHexagonVectorSource();
      if (!prevProps.hexagonPoints && this.props.drawHexagons) {
        this.renderHexagons();
      }
    }

    if (
      this.props.regions !== prevProps.regions ||
      this.props.regionSelection !== prevProps.regionSelection
    ) {
      this.setupRegionVectorSource();
      if (this.props.drawRegions) {
        this.renderRegions();
      }
    }

    if (this.props.heatmapPoints !== prevProps.heatmapPoints) {
      this.setupHeatmapVectorSource();
      if (!prevProps.heatmapPoints && this.props.drawHeatmap) {
        this.renderHeatmap();
      }
    }

    if (
      this.props.styleFunction !== prevProps.styleFunction ||
      this.props.drawPoints !== prevProps.drawPoints
    ) {
      if (this.props.drawPoints) {
        this.renderPoints();
      } else {
        if (this.pointLayer) {
          this.map.removeLayer(this.pointLayer);
        }
      }
    }

    if (this.props.drawHexagons !== prevProps.drawHexagons) {
      if (this.props.drawHexagons) {
        this.renderHexagons();
      } else {
        if (this.hexagonLayer) {
          this.map.removeLayer(this.hexagonLayer);
        }
      }
    }

    if (this.props.drawRegions !== prevProps.drawRegions) {
      if (this.props.drawRegions) {
        this.renderRegions();
      } else {
        if (this.regionLayer) {
          this.map.removeLayer(this.regionLayer);
        }
      }
    }

    if (this.props.drawHeatmap !== prevProps.drawHeatmap) {
      if (this.props.drawHeatmap) {
        this.renderHeatmap();
      } else {
        if (this.heatmapLayer) {
          this.map.removeLayer(this.heatmapLayer);
        }
      }
    }

    if (
      this.pointLayer &&
      this.props.pointUpdateUuid !== prevProps.pointUpdateUuid
    ) {
      this.updatePointFeatures();
      this.pointLayer.changed();
    }

    if (
      this.hexagonLayer &&
      this.props.hexagonUpdateUuid !== prevProps.hexagonUpdateUuid
    ) {
      this.hexagonLayer.changed();
    }

    if (
      this.regionLayer &&
      this.props.regionUpdateUuid !== prevProps.regionUpdateUuid
    ) {
      this.regionLayer.changed();
    }

    if (
      this.heatmapLayer &&
      this.props.heatmapUpdateUuid !== prevProps.heatmapUpdateUuid
    ) {
      this.updateBlurForZoom(this, null);
      this.heatmapLayer.changed();
    }

    if (this.props.heatmapGradient !== prevProps.heatmapGradient) {
      this.renderHeatmap(true);
    }

    if (this.props.hexagonSize !== prevProps.hexagonSize) {
      if (this.hexBin) {
        let hexagonSize = this.props.hexagonSize;
        if (hexagonSize <= 10) {
          hexagonSize = hexagonSize * 1250; // support fractional mile hexagon sizes
        }
        this.hexBin.setSize(hexagonSize);
      }
    }

    if (this.props.hexagonOpacity !== prevProps.hexagonOpacity) {
      if (this.hexagonLayer) {
        this.hexagonLayer.setOpacity((this.props.hexagonOpacity || 50) / 100);
      }
    }

    if (this.props.heatmapOpacity !== prevProps.heatmapOpacity) {
      if (this.heatmapLayer) {
        this.heatmapLayer.setOpacity((this.props.heatmapOpacity || 80) / 100);
      }
    }

    if (this.props.regionOpacity !== prevProps.regionOpacity) {
      if (this.regionLayer) {
        this.regionLayer.setOpacity((this.props.regionOpacity || 50) / 100);
      }
    }

    // This second resize is necessary to ensure the map uses the whole window (no overflowy)
    if (
      this.state.innerHeight != prevState.innerHeight ||
      this.props.height != prevProps.height ||
      this.props.width != prevProps.width
    ) {
      if (this.map) {
        this.map.updateSize();
      }
    }

    if (this.props.focusedFeature !== prevProps.focusedFeature) {
      this.focusFeature(this.props.focusedFeature);
    }
  }

  render = () => {
    const { classes } = this.props;

    return (
      <div
        className="flex-fullHeight-container no-overflowy"
        style={
          this.props.dashboardMode
            ? { backgroundColor: "darkGray", borderRadius: "4px" }
            : { backgroundColor: "darkGray" }
        }
      >
        <div
          id="map"
          className={clsx(
            classes.content,
            this.props.dashboardMode && "dashboardMapDiv",
            { [classes.contentShift]: this.state.detailsOpen }
          )}
          ref={this.mapRef}
          style={
            this.props.dashboardMode
              ? {
                  position: "relative",
                  height: this.props.height || "446px",
                  borderRadius: "4px"
                }
              : {
                  position: "relative",
                  flex: "1 1 auto",
                  height: this.state.innerHeight + "px"
                }
          }
        >
          <div
            ref={this.tooltipAnchorRef}
            id={"tooltipAnchor" + this.props.id}
          />
          <BaseTooltip
            anchorEl={
              (this.tooltipAnchorRef && this.tooltipAnchorRef.current) || (
                <div />
              )
            }
            id={this.props.id}
            open={this.state.tooltipOpen}
            position={this.state.tooltipPosition}
          >
            {this.props.tooltipComponent &&
              React.cloneElement(this.props.tooltipComponent, {
                features: this.state.tooltipFeatures
              })}
          </BaseTooltip>
          <div
            className={
              (hasTouch() ? "ol-touch " : "") +
              classes.optionsButtonsOuterContainer
            }
          >
            <div
              className={
                hasTouch()
                  ? classes.optionsButtonsInnerContainerTouch
                  : classes.optionsButtonsInnerContainer
              }
            >
              <div className="over-popover map-control ol-unselectable">
                <ZoomInButton
                  in={this.props.showZoomButtons}
                  onClick={this.increaseZoom}
                />
                <ZoomOutButton
                  in={this.props.showZoomButtons}
                  onClick={this.decreaseZoom}
                />
                <SatelliteButton
                  in={this.props.showSatelliteButton}
                  pressed={this.props.showSatellite}
                  onClick={this.toggleSatellite}
                />
                <ResetButton
                  in={this.props.showResetButton}
                  onClick={this.resetMapView}
                />
                <NewTabButton
                  in={this.props.showNewTabButton}
                  onClick={this.props.newTabFunction}
                />
                <ShowKeyButton
                  in={this.props.showKeyButton}
                  pressed={this.state.keyOpen}
                  onClick={this.toggleKey}
                />
                <ShowDetailsButton
                  in={this.props.showDetailsButton}
                  pressed={this.state.detailsOpen}
                  onClick={this.toggleDetails}
                />
              </div>
            </div>
          </div>
          <MapKey in={this.state.keyOpen}>{this.props.keyContents}</MapKey>
          <div className={classes.descriptionContainer}>
            <div className="map-description-text">{this.props.description}</div>
          </div>
          <LoadSpinner in={this.props.loading || this.props.loadingData} />
        </div>
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="bottom"
          open={this.state.detailsOpen}
          classes={{
            paper: classes.drawerPaper
          }}
        >
          {this.state.detailsOpen && this.props.drawerContents}
        </Drawer>
      </div>
    );
  };

  toggleDetails = () => {
    this.setState({ detailsOpen: !this.state.detailsOpen });
  };

  resizeComponents = () => {
    let innerHeight = getAvailableWindowHeight();
    this.setState({ innerHeight: innerHeight });
  };

  startAnimation = () => {
    if (this.mapRef.current) {
      if (this.state.detailsOpen) {
        this.mapRef.current.style =
          "position: relative; flex: 1 1 auto; transition: height 0.225s ease-out; height: " +
          (this.state.innerHeight - drawerHeight) +
          "px";
        var timer = setTimeout(this.resizePostAnimation, 250);
      } else {
        this.mapRef.current.style =
          "position: relative; flex: 1 1 auto; transition: height 0.125s sharp; height: " +
          this.state.innerHeight +
          "px";
        var timer = setTimeout(this.resizePreAnimation, 200);
      }
    }
  };

  resizePostAnimation = () => {
    let oldSize = this.map.getSize();
    let fakeCenter = [oldSize[0] / 2, 0];

    fakeCenter[1] = Math.ceil((oldSize[1] - drawerHeight) / 2);

    let coordinate = this.map.getCoordinateFromPixel(fakeCenter);
    this.map.updateSize();
    this.view.setCenter(coordinate);
  };

  resizePreAnimation = () => {
    let oldSize = this.map.getSize();
    let fakeCenter = [oldSize[0] / 2, 0];
    fakeCenter[1] = (oldSize[1] + drawerHeight) / 2;
    let coordinate = this.map.getCoordinateFromPixel(fakeCenter);
    this.map.updateSize();
    this.view.setCenter(coordinate);
  };

  setupMap = () => {
    if (this.state.setup == true) return;

    var loadTiles = !hasTouch();

    var lonLat = [this.props.longitude, this.props.latitude];
    var mercCoords = fromLonLat(lonLat);

    this.tileLayer = new TileLayer({
      source: new XYZ({
        url: "https://tileserver.levrum.com/{z}/{x}/{y}.png",
        attributions: [ATTRIBUTION]
      }),
      opacity: 1
    });

    this.satelliteLayer = new TileLayer({
      source: new XYZ({
        url:
          "https://api.maptiler.com/maps/hybrid/256/{z}/{x}/{y}.jpg?key=GorAwqJMozIDkzsAA6JG",
        attributions: [MAPTILER_ATTRIBUTION]
      }),
      opacity: 1
    });

    this.view = new View({
      center: mercCoords,
      zoom: this.props.zoom,
      maxZoom: 18,
      minZoom: 2
    });

    let displayedLayer = this.props.showSatellite
      ? this.satelliteLayer
      : this.tileLayer;

    this.map = new Map({
      controls: defaultControls({
        attribution: true,
        attributionOptions: {
          collapsible: false
        },
        rotate: false,
        zoom: false
      }).extend([]),
      renderer: "webgl",
      layers: [displayedLayer],
      target: this.mapRef.current,
      view: this.view,
      loadTilesWhileAnimating: loadTiles,
      loadTilesWhileInteracting: loadTiles
    });

    this.select = new Select({});

    this.map.addInteraction(this.select);
    this.select.on("select", this.onSelectionChanged);

    this.view.on("change:resolution", evt => {
      this.startUpdateDisplayedFeatures();
      this.updateZoomFunction(this, evt);
    });

    this.map.on("pointermove", this.onPointerMove);
    this.map.updateSize();

    this.map.on("moveend", this.onMoveEnd);

    if (!hasTouch() && DEVICE_PIXEL_RATIO == 1) {
      var unselectableElements = this.mapRef.current.getElementsByClassName(
        "ol-unselectable"
      );

      Array.prototype.filter.call(unselectableElements, function(element) {
        if (element.tagName == "CANVAS") {
          element.setAttribute(
            "style",
            `width: ${element.offsetWidth}; height: ${element.offsetHeight}`
          );
        }
      });
    }

    this.setState({ setup: true });
  };

  cleanupMapElements = () => {
    let olPointKeys = Object.keys(this.olPoints);
    for (var i = 0; i < olPointKeys.length; i++) {
      delete this.olPoints[olPointKeys[i]];
    }

    if (this.vectorSource) {
      this.vectorSource.clear();
      this.vectorSource.dispose();
      delete this.vectorSource;
    }

    if (this.hexagonVectorSource) {
      this.hexagonVectorSource.clear();
      this.hexagonVectorSource.dispose();
      delete this.hexagonVectorSource;
    }

    if (this.hexBin) {
      this.hexBin.clear();
      this.hexBin.dispose();
      delete this.hexBin;
    }

    let layers = this.map.getLayers().getArray();
    for (var i = 0; i < layers.length; i++) {
      layers[i].dispose();
      this.map.removeLayer(layers[i]);
    }

    delete this.pointLayer;
    delete this.hexagonLayer;
    delete this.heatmapLayer;
    delete this.tileLayer;
    delete this.satelliteLayer;

    if (this.map) {
      this.map.dispose();
      delete this.map;
    }

    if (this.view) {
      this.view.dispose();
      delete this.view;
    }
  };

  updateRasterLayer = () => {
    this.map.removeLayer(this.tileLayer);
    this.map.removeLayer(this.satelliteLayer);

    let displayedLayer = this.props.showSatellite
      ? this.satelliteLayer
      : this.tileLayer;
    this.map.addLayer(displayedLayer);
  };

  increaseZoom = () => {
    this.zoomDelta(1);
  };

  decreaseZoom = () => {
    this.zoomDelta(-1);
  };

  zoomDelta = delta => {
    var view = this.view;
    if (!this.view) return;

    view.animate({
      zoom: view.getZoom() + delta,
      duration: 250
    });
  };

  toggleSatellite = () => {
    this.props.setSettings({ showSatellite: !this.props.showSatellite });
  };

  toggleKey = () => {
    this.setState({ keyOpen: !this.state.keyOpen });
  };

  resetMapView = () => {
    if (!this.view) {
      return;
    }

    var lonLat = [this.props.longitude, this.props.latitude];
    var mercCoords = fromLonLat(lonLat);

    this.view.setCenter(mercCoords);
    this.view.setZoom(this.props.zoom);
  };

  focusFeature = feature => {
    if (!feature) {
      return;
    }
    var geometry = feature.getGeometry();
    if (!geometry) {
      return;
    }

    this.view.fit(geometry, { maxZoom: 16 });
    this.flashFeature(feature);
  };

  flashDuration = 1500;
  start = null;

  flashFeature = feature => {
    this.start = new Date().getTime();
    this.flashingFeature = feature;
    this.listenerKey = this.tileLayer.on("postrender", this.animateFlash);
  };

  animateFlash = event => {
    var vectorContext = getVectorContext(event);
    var frameState = event.frameState;
    var flashGeom = this.flashingFeature.getGeometry().clone();
    var elapsed = frameState.time - this.start;
    var elapsedRatio = elapsed / this.flashDuration;
    // radius will be 5 at start and 30 at end.
    var radius = easeOut(elapsedRatio) * 25 + 5;
    var opacity = easeOut(1 - elapsedRatio);

    var style = new Style({
      image: new Circle({
        radius: radius,
        stroke: new Stroke({
          color: "rgba(255, 0, 0, " + opacity + ")",
          width: 0.25 + opacity
        })
      })
    });

    vectorContext.setStyle(style);
    vectorContext.drawGeometry(flashGeom);
    if (elapsed > this.flashDuration) {
      unByKey(this.listenerKey);
      return;
    }
    // tell OpenLayers to continue postrender animation
    this.map.render();
  };

  onPointerMove = e => {
    if (this.props.displayTooltips == false || e.dragging) {
      return;
    }

    var pixel = this.map.getEventPixel(e.originalEvent);
    var hit = this.map.hasFeatureAtPixel(pixel);
    if (hit) {
      this.showTooltip(e);
    } else {
      this.disposeTooltip();
      this.setState({ tooltipFeatures: [] });
    }
  };

  disposeTooltip = () => {
    if (this.tooltip) {
      this.map.removeOverlay(this.tooltip);
      delete this.tooltip;

      this.setState({ tooltipOpen: false });
    }
  };

  toggleTooltip = () => {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });
  };

  showTooltip = e => {
    var latestFeatures = [];

    this.map.forEachFeatureAtPixel(e.pixel, feature => {
      if (feature.get("type") !== "heat") {
        latestFeatures.push(feature);
      }
    });

    if (latestFeatures.length == 0) {
      return;
    }

    this.setState({
      tooltipFeatures: latestFeatures
    });
    if (this.state.tooltipOpen) {
      this.toggleTooltip();
    }

    var element = this.tooltipAnchorRef.current;
    if (!element) {
      return;
    }

    if (this.tooltip) {
      this.map.removeOverlay(this.tooltip);
      delete this.tooltip;
    }

    var tooltip = new Overlay({
      element: element,
      positioning: "bottom-center",
      stopEvent: false,
      offset: [0, 0]
    });

    tooltip.on("change:position", event => {
      this.toggleTooltip();
    });

    this.tooltip = tooltip;
    this.map.addOverlay(tooltip);

    tooltip.setPosition(this.map.getCoordinateFromPixel(e.pixel));
  };

  onMoveEnd = event => {
    if (!this.view) {
      return;
    }

    var center = this.view.getCenter();
    var lonLat = toLonLat(center);

    this.props.setSettings({
      currentLongitude: lonLat[0],
      currentLatitude: lonLat[1]
    });

    this.startUpdateDisplayedFeatures();
  };

  onSelectionChanged = e => {
    var features = e.target.getFeatures();
    var selectedFeatures = [];
    var array = features.getArray();
    for (var i = 0; i < array.length; i++) {
      let subFeatures = array[i].get("features");
      if (subFeatures) {
        for (var k = 0; k < subFeatures.length; k++) {
          selectedFeatures.push(subFeatures[k]);
        }
      } else {
        selectedFeatures.push(array[i]);
      }
    }
    this.props.setSettings({ selectedFeatures: selectedFeatures });
    this.updatePointFeatures();
  };

  startUpdateDisplayedFeatures = () => {
    if (this.updateDisplayedFeaturesTimer) {
      clearTimeout(this.updateDisplayedFeaturesTimer);
    }
    this.updateDisplayedFeaturesTimer = setTimeout(
      this.updateDisplayedFeatures,
      100
    );
  };

  updateDisplayedFeaturesTimer = undefined;

  updateDisplayedFeatures = () => {
    if (!this.view || !this.vectorSource) {
      return;
    }

    var extent = this.view.calculateExtent(this.map.getSize());
    var pointFeatures = this.vectorSource.getFeaturesInExtent(extent);
    this.props.setSettings({ displayedFeatures: pointFeatures });
  };

  setupPointVectorSource = async () => {
    if (!this.props.points) {
      return;
    }

    var lonLat = [0.0, 0.0];
    let point, merc, feature;

    if (!this.vectorSource) {
      this.vectorSource = new VectorSource();
    } else {
      this.vectorSource.clear();
    }

    var pointKey, olPoint, olFeature;
    for (var i = 0; i < this.props.points.length; i++) {
      point = this.props.points[i];
      if (point.longitude && point.latitude) {
        lonLat[0] = point.longitude;
        lonLat[1] = point.latitude;
        pointKey = point.longitude + point.latitude;
      }
      if (this.olPoints[pointKey]) {
        olPoint = this.olPoints[pointKey];
      } else {
        merc = fromLonLat(lonLat);
        olPoint = new Point(merc);
        this.olPoints[pointKey] = olPoint;
      }
      feature = new Feature({
        geometry: olPoint,
        data: point.data,
        type: "point"
      });

      this.props.styleFunction(feature);
      this.vectorSource.addFeature(feature);
    }
    this.updateDisplayedFeatures();
  };

  renderPoints = async () => {
    if (!this.vectorSource) {
      return;
    }

    if (!this.pointLayer) {
      this.pointLayer = new VectorLayer({
        source: this.vectorSource,
        renderMode: "image"
      });
      this.pointLayer.setZIndex(3);
    } else {
      this.pointLayer.setSource(this.vectorSource);
    }

    if (
      !this.map
        .getLayers()
        .getArray()
        .includes(this.pointLayer)
    ) {
      this.map.addLayer(this.pointLayer);
    }
  };

  updatePointFeatures = () => {
    if (!this.vectorSource) {
      return;
    }

    var features = this.vectorSource.getFeatures();
    for (var i = 0; i < features.length; i++) {
      this.props.styleFunction(features[i]);
    }
  };

  setupHexagonVectorSource = async () => {
    if (!this.props.hexagonPoints) {
      return;
    }

    if (!this.hexagonVectorSource) {
      this.hexagonVectorSource = new VectorSource();
    } else {
      this.hexagonVectorSource.clear();
    }

    var lonLat = [0.0, 0.0];
    var point, merc, feature;
    var pointKey, olPoint, olFeature;
    for (var i = 0; i < this.props.hexagonPoints.length; i++) {
      point = this.props.hexagonPoints[i];
      if (point.longitude && point.latitude) {
        lonLat[0] = point.longitude;
        lonLat[1] = point.latitude;
        pointKey = point.longitude + point.latitude;
      }
      if (this.olPoints[pointKey]) {
        olPoint = this.olPoints[pointKey];
      } else {
        merc = fromLonLat(lonLat);
        olPoint = new Point(merc);
        this.olPoints[pointKey] = olPoint;
      }
      feature = new Feature({
        geometry: olPoint,
        data: point.data,
        count: point.data.length,
        type: "hexagon"
      });

      this.hexagonVectorSource.addFeature(feature);
    }
  };

  renderHexagons = async () => {
    if (this.hexBin) {
      this.hexBin.clear();
    }

    if (!this.hexagonVectorSource) {
      return;
    }
    let hexagonSize = this.props.hexagonSize;
    if (hexagonSize <= 10) {
      hexagonSize = hexagonSize * 1250; // support fractional mile hexagon sizes
    }

    var binSize = hexagonSize || 250;

    if (!this.hexBin) {
      this.hexBin = new HexBin({
        source: this.hexagonVectorSource,
        size: binSize
      });
      this.props.setSettings({ hexBin: this.hexBin });
    } else {
      this.hexBin.setSize(binSize);
    }

    if (!this.hexagonLayer) {
      this.hexagonLayer = new VectorLayer({
        source: this.hexBin,
        opacity: this.props.hexagonOpacity / 100 || 0.5,
        style: this.props.hexagonStyleFunction || this.hexagonColorStyle,
        renderMode: "image"
      });
      this.hexagonLayer.setZIndex(3);
    } else {
      this.hexagonLayer.setSource(this.hexBin);
    }

    if (
      !this.map
        .getLayers()
        .getArray()
        .includes(this.hexagonLayer)
    ) {
      this.map.addLayer(this.hexagonLayer);
    }
  };

  setupRegionVectorSource = () => {
    if (!this.regionVectorSource) {
      this.regionVectorSource = new VectorSource();
    }
    this.regionVectorSource.clear();

    var regions = [];
    if (this.props.regionSelection && this.props.regionSelection.length > 0) {
      for (var i = 0; i < this.props.regions.length; i++) {
        if (this.props.regionSelection.includes(this.props.regions[i].name)) {
          regions.push(this.props.regions[i]);
        }
      }
    } else {
      regions = this.props.regions || regions;
    }

    var region, geoJSON, features;
    for (var i = 0; i < regions.length; i++) {
      region = regions[i];
      try {
        geoJSON = JSON.parse(region.geoJSON);
        if (!geojson_validator.valid(geoJSON)) {
          continue;
        }
        var features = new GeoJSON().readFeatures(geoJSON);
        for (var j = 0; j < features.length; j++) {
          features[j].setProperties({
            data: region.incidents,
            type: "region",
            name: region.name
          });
        }
        this.regionVectorSource.addFeatures(features);
      } catch (error) {
        console.error(error);
        continue;
      }
    }
  };

  renderRegions = () => {
    if (!this.regionVectorSource) {
      return;
    }

    if (!this.regionLayer) {
      this.regionLayer = new VectorLayer({
        source: this.regionVectorSource,
        opacity: this.props.regionOpacity / 100 || 0.5,
        style: this.props.regionStyleFunction
      });
      this.regionLayer.setZIndex(2);
    } else {
      this.regionLayer.setSource(this.regionVectorSource);
    }

    if (
      !this.map
        .getLayers()
        .getArray()
        .includes(this.regionLayer)
    ) {
      this.map.addLayer(this.regionLayer);
    }
  };

  setupHeatmapVectorSource = () => {
    if (!this.props.heatmapPoints) {
      return;
    }

    if (!this.heatmapVectorSource) {
      this.heatmapVectorSource = new VectorSource();
    } else {
      this.heatmapVectorSource.clear();
    }

    var lonLat = [0.0, 0.0];
    var point, merc, feature;
    var pointKey, olPoint;
    for (var i = 0; i < this.props.heatmapPoints.length; i++) {
      point = this.props.heatmapPoints[i];
      if (point.longitude && point.latitude) {
        lonLat[0] = point.longitude;
        lonLat[1] = point.latitude;
        pointKey = point.longitude + point.latitude;
      }
      if (this.olPoints[pointKey]) {
        olPoint = this.olPoints[pointKey];
      } else {
        merc = fromLonLat(lonLat);
        olPoint = new Point(merc);
        this.olPoints[pointKey] = olPoint;
      }
      feature = new Feature({
        geometry: olPoint,
        weight: point.value,
        type: "heat"
      });

      this.heatmapVectorSource.addFeature(feature);
    }
  };

  getHeatmapGradient = () => {
    return this.getGradientString(
      heatmapGradientColors[this.props.heatmapGradient]
    );
  };

  getGradientString = colorArrays => {
    var arrays = [];
    for (var i = 0; i < colorArrays.length; i++) {
      arrays.push(getHexColor(colorArrays[i], true));
    }

    return arrays;
  };

  renderHeatmap = recreateLayer => {
    if (!this.heatmapVectorSource) {
      return;
    }

    let oldLayer = undefined;
    if (recreateLayer && this.heatmapLayer) {
      if (
        this.map
          .getLayers()
          .getArray()
          .includes(this.heatmapLayer)
      ) {
        oldLayer = this.heatmapLayer;
      }
      this.heatmapLayer = undefined;
    }

    if (!this.heatmapLayer) {
      this.heatmapLayer = new HeatmapLayer({
        source: this.heatmapVectorSource,
        blur: 10,
        radius: 100,
        opacity: this.props.heatmapOpacity / 100 || 0.8,
        gradient: this.getHeatmapGradient()
      });
      this.heatmapLayer.setZIndex(1);
    } else {
      this.heatmapLayer.setSource(this.heatmapVectorSource);
    }

    if (
      !this.map
        .getLayers()
        .getArray()
        .includes(this.heatmapLayer)
    ) {
      this.map.addLayer(this.heatmapLayer);
      if (oldLayer !== undefined) {
        this.map.removeLayer(oldLayer);
        oldLayer.dispose();
      }
    }

    this.updateBlurForZoom(this, null);
  };

  zoomBlurRadiusSettings = {
    18: [128, 100],
    17: [110, 84],
    16: [100, 75],
    15: [90, 64],
    14: [80, 48],
    13: [65, 32],
    12: [45, 21],
    11: [20, 10],
    10: [12, 6],
    9: [6, 3],
    8: [3, 1.5],
    7: [1.5, 0.75],
    6: [0.75, 0.375],
    5: [0.5, 0.3],
    4: [0.4, 0.25],
    3: [0.3, 0.2],
    2: [0.2, 0.1],
    1: [0.2, 0.1],
    0: [0.2, 0.1]
  };

  updateBlurForZoom = (baseMap, event) => {
    var zoom = Math.floor(this.map.getView().getZoom());

    var vectorLayer = this.heatmapLayer;
    if (!vectorLayer) {
      return;
    } else {
      vectorLayer.setBlur(
        this.zoomBlurRadiusSettings[zoom][0] /
          (2.8 - 2 * (this.props.heatmapBlur / 9))
      );
      vectorLayer.setRadius(
        this.zoomBlurRadiusSettings[zoom][1] /
          (3.8 - 3 * (this.props.heatmapRadius / 9))
      );
    }
  };
}

BaseMapComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

const BaseMapContainer = withStyles(styles)(withTheme(BaseMapComponent));
