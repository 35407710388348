import React, { Component, useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import CustomContainer from "./CustomContainer";
import WelcomePage from "./help/WelcomePage";
import Api from "../services/Api";
import { getNavbarHidden } from "../redux/selectors";
import { toggleNavbarHidden } from "../redux/actions";

const FirstArrivingHome = props => {
  const [rrUser, setUser] = useState(null);
  const [redirectReady, setRedirectReady] = useState(false);
  const [dashboardId, setDashboardId] = useState(0);
  useEffect(() => {
    //send api request to verify key, if good, set as logged in and trigger redirect
    const func = async key => {
      let user = await Api.validateReadyRoomKey(key);
      setUser(user);
    };
    func(props.match.params.key);
  }, []);

  useEffect(() => {
    if (rrUser != null) {
      // Store the user and JWT in local storage
      localStorage.setItem("user", JSON.stringify(rrUser));

      // Now that we know the user is logged in, let's
      // set the default Authorization header for axios
      Api.setAuthorizationHeader(rrUser.jwt);
      if (rrUser.userDashboards.length > 0) {
        let dashboardToUse =
          props.match.params.dashboardId !== undefined
            ? props.match.params.dashboardId
            : 0;
        dashboardToUse = Math.max(0, dashboardToUse - 1); //don't go below 0
        dashboardToUse = Math.min(
          rrUser.userDashboards.length - 1,
          dashboardToUse
        ); //don't go above the total number of dashboards;
        let id = rrUser.userDashboards[dashboardToUse]?.id ?? 0;
        setDashboardId(id);
        setRedirectReady(true);
        props.toggleNavbarHidden(true);
      }
    }
  }, [rrUser]);

  if (redirectReady) {
    return <Redirect to={`/dashboard/${dashboardId}`} />;
  }

  return (
    <CustomContainer>
      <WelcomePage />
    </CustomContainer>
  );
};

const mapStateToProps = state => {
  const navBarHidden = getNavbarHidden(state);
  return { navBarHidden };
};

export default connect(mapStateToProps, { toggleNavbarHidden })(
  FirstArrivingHome
);
