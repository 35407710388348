import React, { Component, useState, useEffect } from "react";
import SimpleReport from "./SimpleReport";
import Api from "../../services/Api";
import { formatObjectDates, roundObjectNumbers } from "../../helpers/Utilities";
import DataContextComponent, { DataContext } from "../DataContext";
import DashboardComponent, { ComponentContext } from "../DashboardComponent";
import { CreateDataObject } from "../../helpers/ReportUtils";
import { CallDetailsSettings } from "./CallDetailsReportSettings";
import moment from "moment";
import { TableRow, TableCell } from "@material-ui/core";

const ZeroTicksMoment = moment("0001-01-01");

export function IncidentReport(props) {
  useEffect(() => {
    props.setSettings({ reportName: "incident" });
    //eslint-disable-next-line
  }, []);
  const fetchData = async dataSettings => {
    const { startDate, endDate } = dataSettings;
    return await Api.getIncidents(startDate, endDate);
  };

  const parseData = data => {
    const toReturn = [];
    data.forEach(ele => {
      delete ele.responses;
      let newDatum = CreateDataObject(ele, true, true);
      toReturn.push(newDatum);
    });
    return toReturn;
  };

  return (
    <SimpleReport
      title={"Incident Report"}
      getData={fetchData}
      parse={parseData}
    />
  );
}

export function ResponsesReport(props) {
  useEffect(() => {
    props.setSettings({ reportName: "responses" });
    //eslint-disable-next-line
  }, []);
  async function fetchData(dataSettings) {
    const { startDate, endDate } = dataSettings;
    return Api.getResponses(startDate, endDate);
  }

  function parseData(data) {
    const toReturn = [];
    data.forEach(ele => {
      delete ele.responses;
      let newDatum = CreateDataObject(ele, true, true);
      delete newDatum.benchmarks;
      toReturn.push(newDatum);
    });
    return toReturn;
  }

  return (
    <SimpleReport
      title={"Responses Report"}
      getData={fetchData}
      parse={parseData}
    />
  );
}

export function UnitReport(props) {
  useEffect(() => {
    props.setSettings({ reportName: "units" });
    //eslint-disable-next-line
  }, []);
  const [toExclude, setExclude] = React.useState([]);
  async function fetchData(dataSettings) {
    const { startDate, endDate } = dataSettings;
    return Api.getUnitDetails(startDate, endDate);
  }

  function parseData(data) {
    if (data && data.length > 0) {
      const toReturn = [];
      let blank = Object.keys(data[0]);
      data.forEach(ele => {
        let newObj;
        newObj = formatObjectDates(ele);
        newObj = roundObjectNumbers(newObj);
        toReturn.push(newObj);
        let oldblank = blank.slice();
        for (let i = 0; i < oldblank.length; i++) {
          let key = oldblank[i];
          if (
            typeof ele[key] !== "undefined" &&
            ele[key] !== null &&
            ele[key] !== ""
          ) {
            let keyIdx = blank.indexOf(key);
            blank.splice(keyIdx, 1);
          }
        }
      });
      setExclude(blank);
      return toReturn;
    } else {
      return [];
    }
  }

  return (
    <SimpleReport
      title={"Unit Details Report"}
      getData={fetchData}
      parse={parseData}
      exclude={toExclude}
    />
  );
}
