import { SET_NAVBAR_HIDDEN } from "../actionTypes";

let navbarHidden = false;

const setNavbarHidden = (state, action) => {
  switch (action.type) {
    case SET_NAVBAR_HIDDEN:
      navbarHidden = action.payload.hidden;
      return navbarHidden;
    default:
      return navbarHidden;
  }
};

export default setNavbarHidden;
