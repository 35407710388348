import React, { Component } from "react";

import {
  Popover,
  Form,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  Spinner,
  Button
} from "reactstrap";

import { connect } from "react-redux";
import { setSettingsEnabled } from "../../redux/actions";

import Api from "../../services/Api.js";

import moment from "moment";
import { Upload } from "../upload/Upload.js";

const styles = {
  label: {
    display: "inline-flex",
    fontSize: "1.25rem",
    fontWeight: "bold",
    width: "10rem"
  },
  value: {
    display: "inline-flex",
    fontSize: "1.25rem"
  },
  uploadButton: {
    marginTop: "1.5rem",
    marginBottom: "-.75rem"
  }
};

class FileSettings extends Component {
  constructor(props) {
    super(props);

    props.setSettingsEnabled(false);
    this.state = {};
  }

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {}

  render() {
    var fileInfo = this.props.flatfileInfo;
    var fileDate = fileInfo
      ? moment(fileInfo.fileDate).format("MMM Do YYYY, h:mm:ssa")
      : "Unknown";
    var fileSize = fileInfo
      ? (fileInfo.length / 1000000).toFixed(2) + " MB"
      : "Unknown";
    var firstIncident = fileInfo
      ? moment(fileInfo.firstIncident).format("MMM Do YYYY, h:mm:ssa")
      : "Unknown";
    var lastIncident = fileInfo
      ? moment(fileInfo.lastIncident).format("MMM Do YYYY, h:mm:ssa")
      : "Unknown";
    return (
      <div>
        <div style={{ width: "410px", margin: "auto" }}>
          <div style={styles.label}>File Date:</div>{" "}
          <div style={styles.value}>{fileDate}</div>
          <br />
          <div style={styles.label}>File Size:</div>{" "}
          <div style={styles.value}>{fileSize}</div>
          <br />
          <div style={styles.label}>First Incident:</div>{" "}
          <div style={styles.value}>{firstIncident}</div>
          <br />
          <div style={styles.label}>Last Incident:</div>{" "}
          <div style={styles.value}>{lastIncident}</div>
          <br />
        </div>
        <div style={styles.uploadButton}>
          <Button
            id="Upload"
            onClick={() => {
              this.setState({ uploadOpen: true });
            }}
            block
          >
            Upload New File
          </Button>
        </div>
        <Upload
          open={this.state.uploadOpen}
          hide={this.hideUpload}
          title="Upload JSON or ZIP File"
          multiple={false}
          uploadData={Api.uploadData}
        ></Upload>
      </div>
    );
  }

  hideUpload = () => {
    this.setState({ uploadOpen: false });
    if (this.props.update) this.props.update();
  };
}

const mapDispatchToProps = {
  setSettingsEnabled
};

const FileSettingsContainer = connect(null, mapDispatchToProps)(FileSettings);

export default FileSettingsContainer;
