import React, { Component, useContext } from "react";
import PropTypes from "prop-types";

import { Redirect } from "react-router-dom";

// Material UI
import {
  withStyles,
  withTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  CircularProgress,
  Typography
} from "@material-ui/core";

// Redux
import { connect } from "react-redux";
import { getSettingsOpen, getSettingsEnabled } from "../../redux/selectors";
import {
  closeSettings,
  toggleSettingsOpen,
  setSettingsEnabled,
  setAllDashboards
} from "../../redux/actions";

// Firewatch
import api from "../../services/Api";
import CustomContainer from "../CustomContainer";

import SettingsMenu from "../controls/SettingsMenu";
import WelcomePage from "../help/WelcomePage";
import NewVersionPage from "../help/NewVersionPage";
import DashboardComponent, { ComponentContext } from "../DashboardComponent";
import { DashboardRenderer } from "./DashboardRenderer";
import DashboardSettingsMenu from "./DashboardSettingsMenu";
import { getDatesFromFilters } from "../../helpers/DashboardHelpers";

const styles = {
  spinnerWrapper: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    position: "absolute",
    top: "50%"
  },
  dashboardTitle: {
    fontSize: "2.5em",
    fontWeight: "400"
  },
  noDashboardInfo: {
    fontSize: "1.5em",
    fontWeight: "500",
    textAlign: "center"
  },
  dashboardFullWidthRow: {
    margin: "auto",
    paddingTop: "4px",
    paddingBottom: "4px"
  },
  dashboardRow: {
    paddingTop: "4px",
    paddingBottom: "4px"
  },
  dashboardFullWidthColumn: {
    margin: "auto"
  },
  dashboardLeftColumn: {
    marginLeft: "auto",
    paddingRight: "3px"
  },
  dashboardRightColumn: {
    marginRight: "auto",
    paddingLeft: "3px"
  },
  dashboardMiddleColumn: {
    paddingLeft: "3px",
    paddingRight: "3px"
  },
  dashboardPaper: {
    width: "100%"
  },
  containerDiv: {
    textAlign: "center",
    padding: "0.2em 0.5em"
  }
};

const defaultSettings = {};
class Dashboard extends Component {
  render() {
    return (
      <DashboardComponent defaultSettings={defaultSettings} {...this.props}>
        <ComponentContext.Consumer>
          {({ setSettings, ...settings }) => (
            <InternalDashboard
              setSettings={setSettings}
              {...settings}
              {...this.props}
            />
          )}
        </ComponentContext.Consumer>
      </DashboardComponent>
    );
  }
}

// const setSettings = newSettings => {
//   let keys = Object.keys(newSettings);
//   for (var i = 0; i < keys.length; i++) {
//     context[keys[i]] = newSettings[keys[i]];
//   }
// };

// const context = {
//   setSettings: setSettings
// };

// export const DashboardContext = React.createContext(context);
// export const useDashboardContext = () => useContext(DashboardContext);
// This is named weird because the component context class is already named DashboardComponent. My bad.
class InternalDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {
    document.title = "Code3 Firewatch - Dashboard";

    window.addEventListener("resize", this.resizeComponents);
    this.loadDashboards();
    this.resizeComponents();
  };

  propsRequiringUpdate = [];
  stateRequiringUpdate = ["loading", "dashboard"];

  shouldComponentUpdate = async (nextProps, nextState) => {
    let updateRequired = false;
    let propKeys = Object.keys(this.props);
    let nextPropKeys = Object.keys(nextProps);
    for (var key in nextPropKeys) {
      if (!propKeys.includes(nextPropKeys[key]))
        propKeys.push(nextPropKeys[key]);
    }

    let changedProps = [];
    let value;
    for (var key in propKeys) {
      value = propKeys[key];
      if (this.props[value] !== nextProps[value]) {
        changedProps.push(value);
        if (this.propsRequiringUpdate.includes(value)) {
          updateRequired = true;
        }
      }
    }

    let stateKeys = Object.keys(this.state);
    let nextStateKeys = Object.keys(nextState);
    for (var key in nextStateKeys) {
      value = nextStateKeys[key];
      if (!stateKeys.includes(value)) stateKeys.push(value);
    }

    let changedState = [];
    for (var key in stateKeys) {
      value = stateKeys[key];
      if (this.state[value] !== nextState[value]) {
        changedState.push(value);
        if (this.stateRequiringUpdate.includes(value)) {
          updateRequired = true;
        }
      }
    }

    return updateRequired;
  };

  componentDidUpdate = async (prevProps, prevState) => {
    if (
      (this.props.match &&
        this.props.match.params &&
        this.props.match.params.dashboardId !=
          prevProps.match.params.dashboardId) ||
      (this.props.match.params.dashboardId && !prevProps.match.params)
    ) {
      this.setState({ showDeleteEmptyDialog: false });
      this.loadDashboards();
    }

    if (this.state.loading != prevState.loading && !this.state.loading) {
      this.resizeComponents();
    }
  };

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.resizeComponents);

    this.props.setSettingsEnabled(false);
    this.props.closeSettings();
    document.title = "Code3 Firewatch";
  };

  loadDashboards = async () => {
    if (this.props.match != null)
      await this.loadDashboard(this.props.match.params.dashboardId);
    else await this.loadHomeDashboard();
  };

  loadHomeDashboard = async () => {
    this.setState({ loading: true });

    const dashboard = await api.getHomeDashboard();
    this.props.setSettingsEnabled(false);

    this.loadDashboardSettings(dashboard);
    this.setState({ loading: false });
  };

  loadDashboard = async dashboardId => {
    // Clear the dashboard first, to help resolve a weird rendering issue where the previous elements would not go away
    this.setState({ loading: true });
    this.props.setSettings({ dashboard: null, dashboardId: dashboardId });

    // Download the selected dashboard
    const dashboard = await api.getDashboard(dashboardId);
    if (!dashboard) {
      this.props.setSettingsEnabled(false);
    } else {
      this.props.setSettingsEnabled(true);
      document.title = dashboard.title
        ? "Code3 Firewatch - " + dashboard.title
        : "Code3 Firewatch";
    }

    this.loadDashboardSettings(dashboard);
    this.setState({ loading: false });
  };

  resizeComponents = () => {
    const marginOffset = 40;
    var navMenuHeight = 0;
    var dashboardTitleHeight = 0;
    const navMenu = document.getElementById("navMenu");
    const dashboardTitle = document.getElementById("dashboardTitle");
    if (navMenu) navMenuHeight = navMenu.offsetHeight;

    if (dashboardTitle) dashboardTitleHeight = dashboardTitle.offsetHeight;

    this.setState({
      dashboardHeight:
        window.innerHeight -
        marginOffset -
        navMenuHeight -
        dashboardTitleHeight,
      dashboardWidth: window.innerWidth
    });
  };

  loadDashboardSettings = dashboard => {
    let orderedDashboardItems = [];
    let settings = {};
    if (dashboard && dashboard.dashboardItems) {
      let dashboardItem;
      for (var i = 0; i < dashboard.dashboardItems.length; i++) {
        dashboardItem = dashboard.dashboardItems[i];
        dashboardItem.parsedFilters = JSON.parse(dashboardItem.filters);
        if (
          (dashboardItem.parsedFilters?.dateRange ?? "Selected Dates") !==
          "Selected Dates"
        ) {
          let [startdate, enddate] = getDatesFromFilters(
            dashboardItem.parsedFilters
          );
          dashboardItem.parsedFilters.startDate = startdate;
          dashboardItem.parsedFilters.endDate = enddate;
          dashboardItem.parsedFilters.dateText = startdate + " - " + enddate;
        }
        orderedDashboardItems.push(dashboard.dashboardItems[i]);
        settings[dashboard.dashboardItems[i].id] = dashboard.dashboardItems[i];
      }
    }

    orderedDashboardItems.sort((a, b) => {
      return a.desiredOrder - b.desiredOrder;
    });

    settings["dashboard"] = dashboard;
    settings["orderedDashboardItems"] = orderedDashboardItems;

    this.props.setSettings(settings);
    // this.setState(settings);
  };

  renderRedirect = () => {
    if (this.state.redirect) {
      return <Redirect to="/" />;
    }
  };

  deleteDashboard = async () => {
    await api.deleteDashboard(this.props.dashboardId);

    var dashboards = await api.getAllDashboards();
    this.props.setAllDashboards(dashboards);

    this.setState({ redirect: true });
  };

  deleteDashboardItem = async dashboardItemId => {
    await api.deleteDashboardItem(dashboardItemId);

    await this.loadDashboards();
    if (
      this.props.dashboard &&
      (!this.props.dashboard.dashboardItems ||
        this.props.dashboard.dashboardItems.length == 0)
    ) {
      this.setState({ showDeleteEmptyDialog: true });
    }
  };

  saveDashboard = async () => {
    await api.updateDashboard(this.props.dashboard);

    var dashboards = await api.getAllDashboards();
    this.props.setAllDashboards(dashboards);
  };

  hideDeleteEmptyDialog = () => {
    this.setState({ showDeleteEmptyDialog: false });
  };

  render = () => {
    const { classes, dashboard, orderedDashboardItems } = this.props;
    // const { dashboard, orderedDashboardItems } = this.state;
    return (
      //      <DashboardContext.Provider value={this.state}>
      <CustomContainer dashboardContainer={true} id="dashboard-container">
        {this.renderRedirect()}
        <SettingsMenu
          isOpen={this.props.settingsOpen}
          onStateChange={this.menuStateChange}
          customBurgerIcon={false}
          outerContainerId={"dashboard-container"}
          pageWrapId={"dashboard"}
          width={280}
        >
          <DashboardSettingsMenu
            deleteDashboard={this.deleteDashboard}
            saveDashboard={this.saveDashboard}
            deleteDashboardItem={this.deleteDashboardItem}
            {...this.props}
          />
        </SettingsMenu>
        {(this.state.loading == false && dashboard == null && (
          <div /> //originally showed welcome page, don't want to flash it while refreshing (reselecting dashboard shows welcome page bug)
        )) ||
          (this.state.loading == true && (
            <div style={styles.spinnerWrapper}>
              <CircularProgress
                color="secondary"
                style={{ width: "3rem", height: "3rem" }}
              />
            </div>
          )) ||
          (this.props.newContentAvailable && <NewVersionPage />) || (
            <div id="dashboard">
              {dashboard != null && dashboard.title && (
                <div className={classes.containerDiv}>
                  <div
                    id="dashboardTitle"
                    className={classes.dashboardTitleDiv}
                  >
                    <Typography variant="h2" className={classes.dashboardTitle}>
                      {dashboard != null && dashboard.title}
                    </Typography>
                  </div>
                </div>
              )}
              <DashboardRenderer
                dashboard={dashboard}
                dashboardItems={orderedDashboardItems}
                dashboardHeight={this.state.dashboardHeight}
                dashboardWidth={this.state.dashboardWidth}
              />
            </div>
          )}
        {this.state.showDeleteEmptyDialog && (
          <DeleteEmptyDashboardDialog
            open={this.state.showDeleteEmptyDialog}
            onDelete={this.deleteDashboard}
            hide={this.hideDeleteEmptyDialog}
          />
        )}
      </CustomContainer>
      //      </DashboardContext.Provider>
    );
  };
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  const settingsOpen = getSettingsOpen(state);
  const settingsEnabled = getSettingsEnabled(state);
  return { settingsOpen, settingsEnabled };
};

const mapDispatchToProps = {
  toggleSettingsOpen,
  setSettingsEnabled,
  closeSettings,
  setAllDashboards
};

const DashboardContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Dashboard);

export default withStyles(styles)(DashboardContainer);

function DeleteEmptyDashboardDialog(props) {
  return (
    <Dialog open={props.showDeleteEmptyDialog}>
      <DialogTitle>Delete Dashboard?</DialogTitle>
      <DialogContent>
        All items in this dashboard have been deleted. Delete the dashboard?
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => {
            props.deleteDashboard();
            props.hideDeleteEmptyDialog();
          }}
        >
          Delete
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            props.hideDeleteEmptyDialog();
          }}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
