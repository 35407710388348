import React, { Component } from "react";

import {
  gradientColors,
  heatmapGradientColors,
  getCategoryColor,
  setCategoryColor,
  getHexColor
} from "../helpers/Colors";

import { lzw_decode } from "../helpers/DashboardHelpers";

import CategoryColorPicker from "./controls/CategoryColorPicker";

import { AddDashboardDialog } from "./controls/AddDashboard";
import Api from "../services/Api";

export const ComponentContext = React.createContext({});

export default class DashboardComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      setSettings: this.setSettings,
      getSettings: this.getSettings,
      showCategoryColorPicker: this.showCategoryColorPicker,
      colorPickerShown: false,
      showAddDashboardDialog: false
    };

    this.loadSettings(false);
  }

  defaultKeys = [
    "setSettings",
    "getSettings",
    "showCategoryColorPicker",
    "colorPickerShown",
    "showAddDashboardDialog"
  ];

  resetState = () => {};

  componentDidUpdate = (prevProps, prevState) => {
    if (
      (this.props && prevProps && this.props.location !== prevProps.location) ||
      this.props.dashboardSettings !== prevProps.dashboardSettings ||
      this.props.defaultSettings !== prevProps.defaultSettings
    ) {
      this.loadSettings(true);
    }
  };

  loadSettings = async useSetState => {
    let defaultSettings = this.props.defaultSettings;
    let itemId = this.props.itemId;
    let settings = this.props.dashboardSettings;
    if (settings) {
      settings.description = this.props.title || settings.description;
    } else if (itemId) {
      let dashboardItem = await Api.getDashboardItem(itemId);
      settings =
        dashboardItem?.parsedFilters ?? JSON.parse(dashboardItem.filters);
    } else if (
      this.props &&
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.encodedOptions
    ) {
      let lzw = lzw_decode(this.props.match.params.encodedOptions);
      settings = JSON.parse(lzw);
    }

    let state = {};

    if (settings) {
      if (this.props.setDataSettings) {
        this.props.setDataSettings({
          startDate: settings.startDate,
          endDate: settings.endDate,
          dateRange: settings.dateRange,
          dataFilters: settings.dataFilters || []
        });
      }
      let keys = Object.keys(defaultSettings);
      for (var i = 0; i < keys.length; i++) {
        if (!settings[keys[i]]) {
          settings[keys[i]] = defaultSettings[keys[i]];
        }
      }
    } else {
      settings = defaultSettings;
    }

    settings = settings ? settings : defaultSettings;
    let settingsKeys = Object.keys(settings);
    for (var i = 0; i < settingsKeys.length; i++) {
      if (useSetState) {
        state[settingsKeys[i]] = settings[settingsKeys[i]];
      } else {
        this.state[settingsKeys[i]] = settings[settingsKeys[i]];
      }
    }

    let stateKeys = Object.keys(this.state);
    for (var i = 0; i < stateKeys.length; i++) {
      if (
        !settingsKeys.includes(stateKeys[i]) &&
        !this.defaultKeys.includes(stateKeys[i])
      ) {
        var key = stateKeys[i];
        if (useSetState) {
          this.setState({ [key]: undefined });
        } else {
          state[key] = undefined;
        }
      }
    }

    if (useSetState) {
      this.setState(state);
    }
  };

  getSettings = () => {
    var settings = Object.assign({}, this.state);
    delete settings.setSettings;
    delete settings.getSettings;
    delete settings.showCategoryColorPicker;
    delete settings.showAddDashboardDialog;
    delete settings.hexBin;
    delete settings.points;
    delete settings.benchmarks;
    delete settings.hexagonPoints;
    delete settings.hexBenchmarks;
    delete settings.displayedFeatures;
    delete settings.selectedFeatures;
    delete settings.focusedFeature;
    delete settings.benchmarks;
    delete settings.frequencies;
    delete settings.customize;
    delete settings.itemId;
    delete settings.pivot;
    delete settings.columns;
    delete settings.colors;
    delete settings.dataTypes;
    delete settings.colorPickerShown;
    delete settings.pointGroupOpen;
    delete settings.hexGroupOpen;
    delete settings.heatmapGroupOpen;
    delete settings.currentLatitude;
    delete settings.currentLongitude;
    delete settings.loading;
    delete settings.heatmapMode;
    delete settings.regions;
    delete settings.heatmapPoints;

    if (this.props.getDataSettings) {
      var dataSettings = this.props.getDataSettings();
      settings.startDate = dataSettings.startDate;
      settings.endDate = dataSettings.endDate;
      settings.dateRange = dataSettings.dateRange;
      settings.dataFilters = dataSettings.dataFilters;
    }

    return settings;
  };

  setSettings = newSettings => {
    this.setState(newSettings);
  };

  render() {
    return (
      <ComponentContext.Provider value={this.state}>
        {this.props.children}
        <CategoryColorPicker
          categories={this.state.colorPickerCategories}
          setCategoryColor={this.setCategoryColor}
          getColorForCategory={this.getColorForCategory}
          open={this.state.colorPickerShown}
          onClose={this.hideColorPicker}
        />
        <AddDashboardDialog
          open={!this.props.dashboardMode && this.state.showAddDashboardDialog}
          type={this.state.componentType}
          subtext={this.state.title}
          hide={this.hideAddDashboardDialog}
          getFilters={this.getSettings}
        />
      </ComponentContext.Provider>
    );
  }

  showCategoryColorPicker = dataTypes => {
    let categories = Object.keys(dataTypes).sort();
    this.setState({
      colorPickerCategories: categories,
      colorPickerShown: true
    });
  };

  hideColorPicker = () => {
    this.setState({ colorPickerShown: false });
  };

  hideAddDashboardDialog = () => {
    this.setState({ showAddDashboardDialog: false });
  };
}
