import React, { Component } from "react";
import PropTypes from "prop-types";

// Moment
import moment from "moment";

// OpenLayers
import { Style, Circle, Fill, Stroke, Text } from "ol/style.js";

// Material UI
import { withStyles, withTheme } from "@material-ui/core";

// Redux
import { connect } from "react-redux";
import { getSettingsOpen } from "../../../redux/selectors";
import {
  toggleSettingsOpen,
  closeSettings,
  setSettingsEnabled
} from "../../../redux/actions";

// Utilities
import uuidv4 from "uuid";

// Code3Firewatch Components
import Api from "../../../services/Api";
import {
  dateRanges,
  getDatesFromFilters,
  getDateRanges,
  excludedData,
  lzw_encode
} from "../../../helpers/DashboardHelpers.js";
import {
  gradientColors,
  heatmapGradientColors,
  getCategoryColor,
  resetNextCategory,
  getHexColor
} from "../../../helpers/Colors";
import DataContextComponent, { DataContext } from "../../DataContext";
import DashboardComponent, { ComponentContext } from "../../DashboardComponent";
import SettingsMenu from "../../controls/SettingsMenu";
import TypeMapSettings from "./TypeMapSettings";
import TypeMapKey from "./TypeMapKey";
import TypeMapTooltip from "./TypeMapTooltip";
import BaseMap, { MapPoint } from "../BaseMap";
import TypeMapDetails from "./TypeMapDetails";

const defaultHeatmapSettings = {
  pointGroupOpen: false,
  drawPoints: false,
  dataTypes: { Category: [] },
  pointDataType: "Category",
  pointSelection: [],
  hexGroupOpen: false,
  drawHexagons: false,
  hexAutoscale: true,
  hexagonSize: 0.5,
  hexDataType: "Category",
  hexSelection: [],
  hexCalculation: "count",
  hexGradient: "Green-Yellow-Red",
  hexLowValue: 1,
  hexHighValue: 10,
  hexagonOpacity: 50,
  heatmapGroupOpen: true,
  drawHeatmap: true,
  heatmapDataType: "Category",
  heatmapSelection: [],
  heatmapMode: true,
  heatmapHeat: 5,
  heatmapBlur: 5,
  heatmapRadius: 5,
  heatmapGradient: "Green-Yellow-Red",
  heatmapOpacity: 80,
  regionDataType: "Category",
  regions: [],
  regionSelection: [],
  regionMeasureFilter: [],
  regionCalculation: "count",
  regionGradient: "Green-Yellow-Red",
  regionLowValue: 2,
  regionHighValue: 10,
  regionAutoscale: true,
  regionOpacity: 50
};

const defaultSettings = {
  pointGroupOpen: true,
  drawPoints: true,
  dataTypes: { Category: [] },
  pointDataType: "Category",
  pointSelection: [],
  hexGroupOpen: false,
  drawHexagons: false,
  hexAutoscale: true,
  hexagonSize: 0.5,
  hexDataType: "Category",
  hexSelection: [],
  hexCalculation: "count",
  hexGradient: "Green-Yellow-Red",
  hexLowValue: 1,
  hexHighValue: 10,
  hexagonOpacity: 50,
  heatmapGroupOpen: false,
  drawHeatmap: false,
  heatmapDataType: "Category",
  heatmapSelection: [],
  heatmapMode: false,
  heatmapHeat: 5,
  heatmapBlur: 5,
  heatmapRadius: 5,
  heatmapGradient: "Green-Yellow-Red",
  heatmapOpacity: 80,
  regionDataType: "Category",
  regions: [],
  regionSelection: [],
  regionMeasureFilter: [],
  regionCalculation: "count",
  regionGradient: "Green-Yellow-Red",
  regionLowValue: 2,
  regionHighValue: 10,
  regionAutoscale: true,
  regionOpacity: 50
};

export const getMostCommonIncidentType = (incidents, dataType) => {
  var commonality = {};
  var dictionary;

  for (var i = 0; i < incidents.length; i++) {
    dictionary = incidents[i].data;
    if (!dictionary) {
      continue;
    }

    if (!commonality[dictionary[dataType]]) {
      commonality[dictionary[dataType]] = 1;
    } else {
      commonality[dictionary[dataType]]++;
    }
  }

  var mostCommon = "";
  var mostCommonValue = 0;
  var keys = Object.keys(commonality);
  for (var i = 0; i < keys.length; i++) {
    if (commonality[keys[i]] > mostCommonValue) {
      mostCommon = keys[i];
      mostCommonValue = commonality[keys[i]];
    }
  }

  return mostCommon;
};

export const getMostCommonFeatureType = (features, dataType) => {
  var data, datum, dictionary;
  var commonality = {};
  for (var i = 0; i < features.length; i++) {
    data = features[i].get("data");
    if (!data) {
      continue;
    }

    for (var j = 0; j < data.length; j++) {
      datum = data[j];
      dictionary = datum.data;
      if (!dictionary || !dictionary[dataType]) {
        continue;
      }

      if (!commonality[dictionary[dataType]]) {
        commonality[dictionary[dataType]] = 1;
      } else {
        commonality[dictionary[dataType]]++;
      }
    }
  }

  var mostCommon = "";
  var mostCommonValue = 0;
  var keys = Object.keys(commonality);
  for (var i = 0; i < keys.length; i++) {
    if (commonality[keys[i]] > mostCommonValue) {
      mostCommon = keys[i];
      mostCommonValue = commonality[keys[i]];
    }
  }

  return mostCommon;
};

// A standalone TypeMap needs its own DataContext and its own ComponentContext. This sets those things up, and gives them the default settings.
export default class StandaloneTypeMap extends Component {
  constructor(props) {
    super(props);

    this.state = {
      heatmapMode: false
    };

    if (
      this.props &&
      this.props.location &&
      this.props.location.pathname &&
      this.props.location.pathname.endsWith("heatmap")
    ) {
      this.state.heatmapMode = true;
    }
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (!this.state.heatmapMode) {
      if (
        this.props &&
        this.props.location &&
        this.props.location.pathname &&
        this.props.location.pathname.endsWith("heatmap")
      ) {
        this.setState({ heatmapMode: true });
      }
    } else {
      if (
        this.props &&
        this.props.location &&
        this.props.location.pathname &&
        !this.props.location.pathname.endsWith("heatmap")
      ) {
        this.setState({ heatmapMode: false });
      }
    }
  };

  render() {
    return (
      <DataContextComponent>
        <DataContext.Consumer>
          {({ setDataSettings, getDataSettings, ...dataSettings }) => (
            // DashboardComponent is a DataContext consumer in this scenario because it needs to save data settings with dashboard items
            <DashboardComponent
              setDataSettings={setDataSettings}
              getDataSettings={getDataSettings}
              defaultSettings={
                this.state.heatmapMode
                  ? defaultHeatmapSettings
                  : defaultSettings
              }
              {...this.props}
            >
              <ComponentContext.Consumer>
                {({ setSettings, ...settings }) => (
                  <TypeMap
                    setSettings={setSettings}
                    setDataSettings={setDataSettings}
                    dashboardMode={false}
                    heatmapMode={this.state.heatmapMode}
                    {...settings}
                    {...dataSettings}
                    {...this.props}
                  >
                    <TypeMapSettings />
                  </TypeMap>
                )}
              </ComponentContext.Consumer>
            </DashboardComponent>
          )}
        </DataContext.Consumer>
      </DataContextComponent>
    );
  }
}

const styles = {};

export class TypeMapComponent extends Component {
  constructor(props) {
    super(props);

    this.gradients = {};
    this.colorStyles = {};

    this.props.setDataSettings({
      usingIncidentsByLocation: true,
      filterIncidentsWithoutResponses: true,
      usingRegions: true
    });

    this.props.setSettings({ componentType: "Type Map" });

    if (this.props && this.props.location && this.props.location.pathname) {
      this.props.setSettings({
        heatmapMode: this.props.location.pathname.endsWith("heatmap")
      });
    }
    this.id = uuidv4();
  }

  state = {};

  componentDidMount = () => {
    this.setupMap();
    this.setupRegionStyles();
    this.setupHexagonStyles();
    if (!this.props.dashboardMode) {
      this.props.setSettingsEnabled(true);
      if (this.props.heatmapMode) {
        document.title = "Code3 Firewatch - Heat Map";
      } else {
        document.title = "Code3 Firewatch - Type Map";
      }
    }
  };

  componentWillUnmount = () => {
    if (!this.props.dashboardMode) {
      this.props.setSettingsEnabled(false);
      this.props.closeSettings();
      document.title = "Code3 Firewatch";
    }
  };

  componentDidUpdate = async (prevProps, prevState) => {
    if (this.props.incidentsByLocation !== prevProps.incidentsByLocation) {
      await this.loadData();
      this.pointBuildRequired = true;
      this.hexagonBuildRequired = true;
      this.heatmapBuildRequired = true;
    }

    if (
      this.props &&
      this.props.location &&
      this.props.location.pathname &&
      (!prevProps.location.pathname ||
        this.props.location.pathname !== prevProps.location.pathname)
    ) {
      let heatmapMode = this.props.location.pathname.endsWith("heatmap");
      this.props.setSettings({
        heatmapMode: heatmapMode,
        heatmapGroupOpen: heatmapMode,
        pointGroupOpen: !heatmapMode
      });
      this.resetMap();
      this.setupMap();
      this.id = uuidv4();
      if (!this.props.dashboardMode) {
        if (heatmapMode) {
          document.title = "Code3 Firewatch - Heat Map";
        } else {
          document.title = "Code3 Firewatch - Type Map";
        }
      }
    }

    if (
      this.props.colorPickerShown !== prevProps.colorPickerShown &&
      !this.props.colorPickerShown
    ) {
      this.gradients = {};
      this.setState({
        pointUpdateUuid: uuidv4(),
        hexagonUpdateUuid: uuidv4(),
        regionUpdateUuid: uuidv4()
      });
    }

    if (this.props.showSatellite !== prevProps.showSatellite) {
      this.colorStyles = {};
      this.gradients = {};
      this.hexStyles = {};
      this.regionStyles = {};
      this.setupHexagonStyles();
      this.setupRegionStyles();
      this.setState({
        pointUpdateUuid: uuidv4(),
        hexagonUpdateUuid: uuidv4(),
        regionUpdateUuid: uuidv4()
      });
    }

    if (this.props.pointSelection !== prevProps.pointSelection) {
      this.pointBuildRequired = true;
    }

    if (this.props.drawPoints && this.pointBuildRequired) {
      this.pointBuildRequired = false;
      this.buildPoints();
    }

    if (this.props.pointDataType !== prevProps.pointDataType) {
      this.setState({ pointUpdateUuid: uuidv4() });
    }

    if (this.props.hexSelection !== prevProps.hexSelection) {
      this.hexagonBuildRequired = true;
    }

    if (
      (this.props.hexBin && !prevProps.hexBin && this.props.hexAutoscale) ||
      (!prevProps.hexBin && this.props.drawHexagons) ||
      this.props.hexAutoscale !== prevProps.hexAutoscale ||
      this.props.hexagonSize !== prevProps.hexagonSize
    ) {
      this.setupHexAutoscale();
    }

    if (this.props.drawHexagons && this.hexagonBuildRequired) {
      this.hexagonBuildRequired = false;
      this.buildHexagonPoints();
    }

    if (
      this.props.hexDataType !== prevProps.hexDataType ||
      this.props.hexHighValue !== prevProps.hexHighValue ||
      this.props.hexLowValue !== prevProps.hexLowValue ||
      this.props.hexGradient !== prevProps.hexGradient ||
      this.props.hexCalculation !== prevProps.hexCalculation
    ) {
      if (this.props.hexGradient !== prevProps.hexGradient) {
        this.setupHexagonStyles();
      }

      this.setState({ hexagonUpdateUuid: uuidv4() });
    }

    if (
      (this.props.regions !== prevProps.regions &&
        (!prevProps.regions || prevProps.regions.length == 0)) ||
      this.props.regionAutoscale !== prevProps.regionAutoscale
    ) {
      if (this.props.regionAutoscale) {
        this.setupRegionAutoscale();
      }
    }

    if (
      this.props.regionMeasureFilter !== prevProps.regionMeasureFilter ||
      this.props.regionCalculation !== prevProps.regionCalculation ||
      this.props.regionDataType !== prevProps.regionDataType ||
      this.props.regionHighValue !== prevProps.regionHighValue ||
      this.props.regionLowValue !== prevProps.regionLowValue ||
      this.props.regionGradient !== prevProps.regionGradient
    ) {
      if (this.props.regionGradient !== prevProps.regionGradient) {
        this.setupRegionStyles();
      }

      this.setState({ regionUpdateUuid: uuidv4() });
    }

    if (
      this.props.heatmapSelection !== prevProps.heatmapSelection ||
      this.props.heatmapHeat !== prevProps.heatmapHeat
    ) {
      this.heatmapBuildRequired = true;
    }

    if (this.props.drawHeatmap && this.heatmapBuildRequired) {
      this.heatmapBuildRequired = false;
      this.buildHeatmapPoints();
    }

    if (
      this.props.heatmapBlur !== prevProps.heatmapBlur ||
      this.props.heatmapRadius !== prevProps.heatmapRadius ||
      this.props.heatmapGradient !== prevProps.heatmapGradient
    ) {
      this.setState({ heatmapUpdateUuid: uuidv4() });
    }
  };

  render() {
    return (
      <div
        id="outer-container"
        style={
          (this.props.dashboardMode && {
            height: this.props.height ? this.props.height + "px" : "450px"
          }) ||
          {}
        }
      >
        {!this.props.dashboardMode && (
          <SettingsMenu
            pageWrapId={this.id}
            outerContainerId={"outer-container"}
            customBurgerIcon={false}
            onStateChange={this.menuStateChange}
            style={"slide"}
            noOverlay={false}
            width={280}
          >
            {this.props.children}
          </SettingsMenu>
        )}
        <BaseMap
          id={this.id}
          dashboardMode={this.props.dashboardMode}
          height={this.props.height}
          styleFunction={this.stylePoint}
          hexagonStyleFunction={this.styleHexagon}
          regionStyleFunction={this.styleRegion}
          showNewTabButton={this.props.dashboardMode}
          showDetailsButton={
            !this.props.dashboardMode && !this.props.heatmapMode
          }
          newTabFunction={this.openNewTab}
          onPointerMove={this.onPointerMove}
          pointUpdateUuid={this.state.pointUpdateUuid}
          hexagonUpdateUuid={this.state.hexagonUpdateUuid}
          regionUpdateUuid={this.state.regionUpdateUuid}
          heatmapUpdateUuid={this.state.heatmapUpdateUuid}
          keyContents={<TypeMapKey />}
          drawerContents={<TypeMapDetails />}
          tooltipComponent={<TypeMapTooltip />}
        />
      </div>
    );
  }

  setupMap = async () => {
    this.props.setSettings({ loading: true });

    if (!this.props.latitude || this.props.latitude == 0) {
      await this.resetMap();
    }

    var dateRanges = await getDateRanges();
    var startMoment = moment(this.props.startDate);
    var maxMoment = moment(dateRanges.maxDate);
    var startDate = this.props.startDate || dateRanges.startDate;
    var endDate = this.props.endDate || dateRanges.endDate;
    if (startMoment > maxMoment) {
      endDate = maxMoment.format("YYYY-MM-DD");
      startDate = maxMoment.subtract(1, "months").format("YYYY-MM-DD");
    }

    this.props.setDataSettings({
      startDate: startDate,
      endDate: endDate,
      minDate: dateRanges.minDate,
      maxDate: dateRanges.maxDate
    });

    await this.props.updateData();
    this.loadData();
    this.props.setSettings({ loading: false });
  };

  resetMap = async () => {
    let mapSettings = await Api.getMapSettings();
    if (mapSettings && !this.props.dashboardMode) {
      this.props.setSettings({
        longitude: mapSettings.longitude,
        latitude: mapSettings.latitude,
        zoom: mapSettings.zoom
      });
    }
  };

  loadData = async () => {
    let data = this.props.incidentsByLocation;

    if (!data) {
      return;
    }

    var dataTypes = {};
    var dictionaryKeys = [];
    var dictionary, key;
    var location;
    var locations = Object.keys(data);
    for (var i = 0; i < locations.length; i++) {
      location = data[locations[i]];
      for (var j = 0; j < location.length; j++) {
        dictionary = location[j].data;
        if (!dictionary) {
          continue;
        }
        dictionaryKeys = Object.keys(dictionary);
        for (var k = 0; k < dictionaryKeys.length; k++) {
          key = dictionaryKeys[k];
          if (excludedData.includes(key)) {
            continue;
          }

          if (!dataTypes[key]) {
            dataTypes[key] = {};
          }

          dataTypes[key][dictionary[key]] = true;
        }
      }
    }

    if (!this.props.categoryColorsSet) {
      var dataTypeKeys = Object.keys(dataTypes);
      for (var i = 0; i < dataTypeKeys.length; i++) {
        resetNextCategory();
        dictionaryKeys = Object.keys(dataTypes[dataTypeKeys[i]]);
        for (var j = 0; j < dictionaryKeys.length; j++) {
          getCategoryColor(dictionaryKeys[j]);
        }
      }
    }

    this.dataTypes = dataTypes;

    this.props.setSettings({
      dataTypes: dataTypes,
      categoryColorsSet: true
    });
  };

  getPointsFromData = (selection, type) => {
    var incident, incidents, latitude, longitude, locationData;
    var points = [];

    var locations = Object.keys(this.props.incidentsByLocation);
    var pointCount = 0;
    var incidentCount = 0;
    for (var i = 0, len = locations.length; i < len; i++) {
      incidents = [];
      locationData = this.props.incidentsByLocation[locations[i]];
      for (var j = 0; j < locationData.length; j++) {
        incident = locationData[j];
        if (this.includeIncident(selection, type, incident)) {
          latitude = incident.latitude;
          longitude = incident.longitude;
          if (isNaN(latitude) || isNaN(longitude)) {
            continue; //NaN lat/longs cause everything to break
          }
          incidents.push(incident);
          incidentCount++;
        }
      }

      if (incidents.length > 0) {
        points.push(new MapPoint(longitude, latitude, 0.0, incidents));
        pointCount++;
      }
    }
    console.log(`Incidents: ${incidentCount} Features: ${pointCount}`);
    return points;
  };

  buildPoints = () => {
    if (!this.props.incidentsByLocation) {
      return;
    }

    console.log(`Getting TypeMap point data:`);
    var points = this.getPointsFromData(
      this.props.pointSelection,
      this.props.pointDataType
    );

    this.props.setDataSettings({
      points: points
    });
  };

  buildHexagonPoints = () => {
    if (!this.props.incidentsByLocation) {
      return;
    }

    console.log(`Getting TypeMap hexagon data:`);
    var points = this.getPointsFromData(
      this.props.hexSelection,
      this.props.hexDataType
    );

    this.props.setDataSettings({
      hexagonPoints: points
    });
  };

  includeIncident = (selection, type, incident) => {
    const dictionary = incident.data;
    if (
      selection &&
      selection.length > 0 &&
      !selection.includes(String(dictionary[type]))
    ) {
      return false;
    }

    return true;
  };

  darkStroke = new Stroke({
    color: [64, 64, 64, 0.8],
    width: 1.5
  });

  liteStroke = new Stroke({
    color: [192, 192, 192, 1],
    width: 1.5
  });

  selectedStyle = new Style({
    image: new Circle({
      radius: 5,
      stroke: this.darkStroke,
      fill: new Fill({
        color: [255, 255, 255, 1]
      })
    }),
    zIndex: 1
  });

  selectedMultiStyle = new Style({
    image: new Circle({
      radius: 7,
      stroke: this.darkStroke,
      fill: new Fill({
        color: [255, 255, 255, 1]
      })
    }),
    zIndex: 1
  });

  satelliteSelectedStyle = new Style({
    image: new Circle({
      radius: 5,
      stroke: this.liteStroke,
      fill: new Fill({
        color: [255, 255, 255, 1]
      })
    }),
    zIndex: 1
  });

  satelliteSelectedMultiStyle = new Style({
    image: new Circle({
      radius: 7,
      stroke: this.liteStroke,
      fill: new Fill({
        color: [255, 255, 255, 1]
      })
    }),
    zIndex: 1
  });

  stylePoint = (feature, resolution) => {
    if (!this.props.pointDataType || this.props.pointDataType === "") {
      return;
    }

    let stroke, alpha;

    const data = feature.get("data");

    if (
      this.props.selectedFeatures &&
      this.props.selectedFeatures.includes(feature)
    ) {
      if (this.showSatellite) {
        if (data.length > 1) {
          feature.setStyle(this.satelliteSelectedMultiStyle);
        } else {
          feature.setStyle(this.satelliteSelectedStyle);
        }
        return;
      } else {
        if (data.length > 1) {
          feature.setStyle(this.selectedMultiStyle);
        } else {
          feature.setStyle(this.selectedStyle);
        }
        return;
      }
    } else {
      if (this.props.showSatellite) {
        stroke = this.liteStroke;
        alpha = 0.95;
      } else {
        stroke = this.darkStroke;
        alpha = 0.65;
      }
    }

    let style, color, colorComponent, dictionary, value;

    var newColor = undefined;
    var gradientKey = "";
    let typeCounts = {};
    for (var i = 0; i < data.length; i++) {
      dictionary = data[i].data;
      if (
        !dictionary ||
        (this.props.pointSelection.length > 0 &&
          !this.props.pointSelection.includes(
            String(dictionary[this.props.pointDataType])
          ))
      ) {
        continue;
      }

      value = dictionary[this.props.pointDataType];
      if (value === null || value === undefined) value = "!UNK!";

      if (!typeCounts[value]) {
        typeCounts[value] = 1;
      } else {
        typeCounts[value] = typeCounts[value] + 1;
      }
    }

    let types = Object.keys(typeCounts);
    if (types.length === 1) {
      if (types[0] !== "!UNK!") {
        color = getCategoryColor(types[0]);
      } else {
        color = [165, 165, 165, 0.6];
      }
    } else {
      newColor = [];
      for (var i = 0; i < types.length; i++) {
        gradientKey += types[i] + typeCounts[types[i]];
        if (types[i] !== "!UNK!") {
          color = getCategoryColor(types[i]);
        } else {
          color = [165, 165, 165, 0.6];
        }
        for (var j = 0; j < typeCounts[types[i]]; j++) {
          newColor.push(color);
        }
      }
    }

    if (!color) {
      color = [165, 165, 165, 0.6];
    }

    var fill;
    if (newColor) {
      style = this.gradients[gradientKey];
      if (!style) {
        var canvas = document.createElement("canvas");
        var context = canvas.getContext("2d");

        // var pixelRatio = DEVICE_PIXEL_RATIO;
        // Use pixelRatio here if needs be.
        var grad = context.createLinearGradient(2, 0, 16, 0);
        if (newColor[0]) {
          grad.addColorStop(
            0,
            `rgba(${newColor[0][0]}, ${newColor[0][1]}, ${newColor[0][2]}, ${alpha})`
          );
        }
        for (var i = 0; i < newColor.length; i++) {
          if (newColor[i]) {
            grad.addColorStop(
              (i + 1) / (newColor.length + 1),
              `rgba(${newColor[i][0]}, ${newColor[i][1]}, ${newColor[i][2]}, ${alpha})`
            );
          }
        }

        fill = new Fill();
        fill.setColor(grad);
        this.gradients[gradientKey] = fill;
        style = new Style({
          image: new Circle({
            radius: 7,
            stroke: stroke,
            fill: fill
          }),
          zIndex: newColor.length
        });
        this.gradients[gradientKey] = style;
      }
    } else if (!this.colorStyles[color]) {
      style = new Style({
        image: new Circle({
          radius: 5,
          stroke: stroke,
          fill: new Fill({
            color: [color[0], color[1], color[2], alpha]
          })
        }),
        zIndex: 1
      });

      this.colorStyles[color] = style;
    } else {
      style = this.colorStyles[color];
    }

    feature.setStyle(style);
  };

  setupRegionAutoscale = () => {
    var features;
    var count;
    var incidentCounts = [];
    var j, featureLength, featureCount;

    var regions = this.props.regions || [];
    for (var i = 0; i < regions.length; i++) {
      if (regions[i].incidents) {
        incidentCounts.push(regions[i].incidents.length);
      }
    }

    incidentCounts.sort((a, b) => {
      return a - b;
    });

    var quartile, decile, low, high;
    if (incidentCounts.length == 0) {
      low = 0;
      high = 0;
    } else {
      quartile = Math.ceil(incidentCounts.length / 4);
      decile = Math.ceil(incidentCounts.length / 10);
      low = incidentCounts[quartile];
      high = incidentCounts[incidentCounts.length - decile];
    }

    this.props.setSettings({
      regionLowValue: low,
      regionHighValue: high
    });
  };

  styleRegion = (f, res) => {
    switch (this.props.regionCalculation) {
      case "measure":
        return this.regionMeasureStyle(f, res);
      case "count":
      default:
        return this.regionCountStyle(f, res);
    }
  };

  regionStyles = {};

  setupRegionStyles = () => {
    const stroke = new Stroke({
      color: [64, 64, 64, 0.8],
      width: 1.5
    });

    const satelliteStroke = new Stroke({
      color: [192, 192, 192, 1],
      width: 1.5
    });

    var interval1, interval2, interval3;
    var colorScheme = gradientColors[this.props.regionGradient];

    interval1 = (colorScheme[1][0] - colorScheme[0][0]) / 50;
    interval2 = (colorScheme[1][1] - colorScheme[0][1]) / 50;
    interval3 = (colorScheme[1][2] - colorScheme[0][2]) / 50;

    for (var i = 0; i < 50; i++) {
      this.regionStyles[i] = new Style({
        fill: new Fill({
          color: [
            colorScheme[0][0] + interval1 * i,
            colorScheme[0][1] + interval2 * i,
            colorScheme[0][2] + interval3 * i,
            1
          ]
        }),
        stroke: this.props.showSatellite ? satelliteStroke : stroke
      });
    }

    interval1 = (colorScheme[2][0] - colorScheme[1][0]) / 50;
    interval2 = (colorScheme[2][1] - colorScheme[1][1]) / 50;
    interval3 = (colorScheme[2][2] - colorScheme[1][2]) / 50;

    var k;
    for (var j = 50; j < 100; j++) {
      k = j - 50;
      this.regionStyles[j] = new Style({
        fill: new Fill({
          color: [
            colorScheme[1][0] + interval1 * k,
            colorScheme[1][1] + interval2 * k,
            colorScheme[1][2] + interval3 * k,
            1
          ]
        }),
        stroke: this.props.showSatellite ? satelliteStroke : stroke
      });
    }
  };

  regionMeasureStyle = (f, res) => {
    const stroke = new Stroke({
      color: [64, 64, 64, 0.8],
      width: 1.5
    });

    const satelliteStroke = new Stroke({
      color: [192, 192, 192, 1],
      width: 1.5
    });

    var incidents = f.get("data");

    var filteredIncidents = [];
    for (var i = 0; i < incidents.length; i++) {
      if (this.props.regionMeasureFilter.length == 0) {
        filteredIncidents.push(incidents[i]);
      } else {
        if (
          this.includeIncident(
            this.props.regionMeasureFilter,
            this.props.regionDataType,
            incidents[i]
          )
        ) {
          filteredIncidents.push(incidents[i]);
        }
      }
    }

    if (filteredIncidents.length == 0) {
      const emptyRegionStyle = new Style({
        stroke: this.props.showSatellite ? satelliteStroke : stroke
      });

      return emptyRegionStyle;
    } else {
      var mostCommonCategory = getMostCommonIncidentType(
        filteredIncidents,
        this.props.regionDataType
      );

      var color = getCategoryColor(mostCommonCategory);

      return new Style({
        stroke: this.props.showSatellite ? satelliteStroke : stroke,
        fill: new Fill({
          color: [color[0], color[1], color[2], 1]
        })
      });
    }
  };

  regionCountStyle = (f, res) => {
    let incidents = f.get("data");

    var filteredIncidents = [];
    for (var i = 0; i < incidents.length; i++) {
      if (this.props.regionMeasureFilter.length == 0) {
        filteredIncidents.push(incidents[i]);
      } else {
        if (
          this.includeIncident(
            this.props.regionMeasureFilter,
            this.props.regionDataType,
            incidents[i]
          )
        ) {
          filteredIncidents.push(incidents[i]);
        }
      }
    }

    let count = filteredIncidents.length;

    let interval =
      (this.props.regionHighValue - this.props.regionLowValue) / 100;
    var styleIndex = Math.max(
      0,
      Math.min(99, Math.floor((count - this.props.regionLowValue) / interval))
    );

    return this.regionStyles[styleIndex];
  };

  setupHexAutoscale = () => {
    if (!this.props.hexBin) {
      return;
    }

    var hexbins = this.props.hexBin.getFeatures();

    var features;
    var count;
    var featureCounts = [];
    var j, featureLength, featureCount;
    for (var i = 0; i < hexbins.length; i++) {
      features = hexbins[i].get("features");
      if (!features) {
        continue;
      }

      featureLength = features.length;
      featureCount = 0;
      for (j = 0; j < featureLength; j++) {
        count = features[j].get("count");
        if (count) {
          featureCount += count;
        }
      }

      featureCounts.push(featureCount);
    }

    featureCounts.sort((a, b) => {
      return a - b;
    });

    var quartile, decile, low, high;
    if (featureCounts.length == 0) {
      low = 0;
      high = 0;
    } else {
      quartile = Math.ceil(featureCounts.length / 4);
      decile = Math.ceil(featureCounts.length / 10);
      low = featureCounts[quartile];
      high = featureCounts[featureCounts.length - decile];
    }

    this.props.setSettings({
      hexLowValue: low,
      hexHighValue: high
    });
  };

  styleHexagon = (f, res) => {
    switch (this.props.hexCalculation) {
      case "measure":
        return this.hexagonMeasureStyle(f, res);
      case "count":
      default:
        return this.hexagonCountStyle(f, res);
    }
  };

  hexStyles = {};

  setupHexagonStyles = () => {
    const stroke = new Stroke({
      color: [64, 64, 64, 0.8],
      width: 1.5
    });

    const satelliteStroke = new Stroke({
      color: [192, 192, 192, 1],
      width: 1.5
    });

    var interval1, interval2, interval3;
    var colorScheme = gradientColors[this.props.hexGradient];

    interval1 = (colorScheme[1][0] - colorScheme[0][0]) / 50;
    interval2 = (colorScheme[1][1] - colorScheme[0][1]) / 50;
    interval3 = (colorScheme[1][2] - colorScheme[0][2]) / 50;

    for (var i = 0; i < 50; i++) {
      this.hexStyles[i] = new Style({
        fill: new Fill({
          color: [
            colorScheme[0][0] + interval1 * i,
            colorScheme[0][1] + interval2 * i,
            colorScheme[0][2] + interval3 * i,
            1
          ]
        }),
        stroke: this.props.showSatellite ? satelliteStroke : stroke
      });
    }

    interval1 = (colorScheme[2][0] - colorScheme[1][0]) / 50;
    interval2 = (colorScheme[2][1] - colorScheme[1][1]) / 50;
    interval3 = (colorScheme[2][2] - colorScheme[1][2]) / 50;

    var k;
    for (var j = 50; j < 100; j++) {
      k = j - 50;
      this.hexStyles[j] = new Style({
        fill: new Fill({
          color: [
            colorScheme[1][0] + interval1 * k,
            colorScheme[1][1] + interval2 * k,
            colorScheme[1][2] + interval3 * k,
            1
          ]
        }),
        stroke: this.props.showSatellite ? satelliteStroke : stroke
      });
    }
  };

  hexagonCountStyle = (f, res) => {
    let features = f.get("features");
    let featureCount = f.get("featureCount");

    if (!featureCount) {
      if (!features) {
        return;
      }

      let featureLength = features.length;
      let featureCount = 0;
      let count;
      for (var i = 0; i < featureLength; i++) {
        count = features[i].get("count");
        featureCount += count ? count : 0;
      }

      f.set("featureCount", featureCount);
    }

    let interval = (this.props.hexHighValue - this.props.hexLowValue) / 100;
    var styleIndex = Math.max(
      0,
      Math.min(
        99,
        Math.floor((featureCount - this.props.hexLowValue) / interval)
      )
    );

    return this.hexStyles[styleIndex];
  };

  hexagonMeasureStyle = (f, res) => {
    var features = f.get("features");
    if (!features) {
      return;
    }

    const stroke = new Stroke({
      color: [64, 64, 64, 0.8],
      width: 1.5
    });

    const satelliteStroke = new Stroke({
      color: [192, 192, 192, 1],
      width: 1.5
    });

    var mostCommonCategory = getMostCommonFeatureType(
      features,
      this.props.hexDataType
    );
    var color = getCategoryColor(mostCommonCategory);

    return new Style({
      fill: new Fill({
        color: [color[0], color[1], color[2], 1]
      }),
      stroke: this.props.showSatellite ? satelliteStroke : stroke
    });
  };

  getWeightsFromData = data => {
    if (!data) {
      return [];
    }

    var locationDetails = {};
    var incident, key;
    var incidentCount = 0;
    for (var i = 0, len = data.length; i < len; i++) {
      incident = data[i];
      key = `${incident.longitude} ${incident.latitude}`;
      if (!locationDetails[key]) {
        locationDetails[key] = {
          location: incident.location,
          longitude: incident.longitude,
          latitude: incident.latitude,
          count: 0
        };
      }
      locationDetails[key].count++;
      incidentCount++;
    }

    var weights = [];
    var keys = Object.keys(locationDetails);
    var details, weight;
    for (var j = 0, keysLen = keys.length; j < keysLen; j++) {
      details = locationDetails[keys[j]];
      weight = 0.1 * details.count * this.props.heatmapHeat;
      if (details.count > 10) {
        for (var k = 0; k < details.count; k = k + 10) {
          weights.push([1, details]);
        }
      }
      weights.push([weight, details]);
    }

    weights.sort(function(a, b) {
      if (a[0] < b[0]) {
        return -1;
      } else if (a[0] > b[0]) {
        return 1;
      } else {
        return 0;
      }
    });

    console.log(`Incidents: ${incidentCount} Features: ${weights.length}`);

    return weights;
  };

  buildHeatmapPoints = () => {
    if (!this.props.incidentsByLocation) {
      return;
    }

    var incident, locationData;
    var data = [];

    console.log(`Getting TypeMap heatmap data:`);
    var locations = Object.keys(this.props.incidentsByLocation);
    for (var i = 0, len = locations.length; i < len; i++) {
      locationData = this.props.incidentsByLocation[locations[i]];
      for (var j = 0; j < locationData.length; j++) {
        incident = locationData[j];
        if (
          this.includeIncident(
            this.props.heatmapSelection,
            this.props.heatmapDataType,
            incident
          )
        ) {
          data.push(incident);
        }
      }
    }

    var weights = this.getWeightsFromData(data);
    var location, dateTime;
    var points = [];
    for (var i = 0, len = weights.length; i < len; i++) {
      location = weights[i][1];
      points.push(
        new MapPoint(
          location.longitude,
          location.latitude,
          weights[i][0],
          location
        )
      );
    }

    this.props.setDataSettings({
      heatmapPoints: points
    });
  };

  openNewTab = () => {
    let settings = this.props.dashboardSettings;
    settings.title = this.props.title;
    let objJsonStr = JSON.stringify(settings);
    let lzw = lzw_encode(objJsonStr);

    var win = window.open("/map/type/" + lzw);
    win.focus();
  };
}

TypeMapComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  const settingsOpen = getSettingsOpen(state);
  return { settingsOpen };
};

const mapDispatchToProps = {
  toggleSettingsOpen,
  closeSettings,
  setSettingsEnabled
};

export const TypeMap = connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true
})(withStyles(styles)(withTheme(TypeMapComponent)));
