import React, { Component } from "react";

import { Link } from "react-router-dom";
import api from "../services/Api";

import { withStyles, makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import ButtonBase from "@material-ui/core/ButtonBase";
import IconButton from "@material-ui/core/IconButton";
import SettingsIcon from "@material-ui/icons/Settings";
import DashboardIcon from "@material-ui/icons/Dashboard";
import HomeIcon from "@material-ui/icons/Home";
import MapIcon from "@material-ui/icons/Map";
import ChartIcon from "@material-ui/icons/BarChart";
import ListIcon from "@material-ui/icons/ListAlt";
import HelpIcon from "@material-ui/icons/Help";
import AccountIcon from "@material-ui/icons/AccountBox";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

import NestedMenuItem from "material-ui-nested-menu-item";

import { connect } from "react-redux";
import {
  toggleSettingsOpen,
  setSettingsEnabled,
  setAllDashboards
} from "../redux/actions";
import {
  getSettingsEnabled,
  getAllDashboards,
  getNavbarHidden
} from "../redux/selectors";
import { render } from "ol/control/ZoomSlider";
import { currentUserHasRole } from "../helpers/Utilities";

const styles = {
  container: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    minHeight: "42px"
  },
  logo: {
    height: "2.5rem"
  },
  menuButton: {
    border: "0",
    background: "transparent",
    marginTop: ".55rem",
    marginRight: ".75rem",
    fontSize: "1.5rem",
    fontFamily: "Material Icons",
    lineHeight: "normal",
    color: "rgba(40,45,47,255)"
  },
  menuSeparator: {
    marginTop: ".5rem",
    marginBottom: ".5rem"
  }
};

const useStyles = theme => ({
  root: {
    zIndex: "1100"
  },
  appBar: {
    background: "rgba(255,255,255,255)",
    color: "rgba(40,45,47,255)",
    marginBottom: "1px"
  },
  title: {
    flexGrow: 1
  },
  menuIconButton: {
    margin: "-.25rem"
  },
  appBarMenu: {
    zIndex: "1300"
  },
  condensedMenuItem: {
    lineHeight: "1.5",
    minHeight: "24px",
    paddingTop: "4px",
    paddingBottom: "4px"
  },
  condensedMenuHeader: {
    opacity: "1 !important",
    fontWeight: "bolder",
    lineHeight: "1.5",
    minHeight: "24px",
    paddingTop: "4px",
    paddingBottom: "4px"
  }
});

class NavMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dashboardAnchorEl: null,
      mapsAnchorEl: null,
      chartsAnchorEl: null,
      reportsAnchorEl: null,
      helpAnchorEl: null,
      accountAnchorEl: null,
      currUser: null
    };
  }

  componentDidMount() {
    if (this.props.userIsLoggedIn) {
      this.setupDashboards();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.userIsLoggedIn != this.props.userIsLoggedIn) {
      // this.props.setSettingsEnabled(this.props.userIsLoggedIn);
      if (!this.props.userIsLoggedIn) {
        this.props.setSettingsEnabled(false);
      }

      if (this.props.userIsLoggedIn) {
        this.setupDashboards();
        this.setupUser();
      }
    }
  }

  setupUser = async () => {
    const user = await api.getCurrentUser();
    if (user != null) {
      this.setState({ currUser: user });
    }
  };

  setupDashboards = async () => {
    const dashboards = await api.getAllDashboards();
    this.props.setAllDashboards(dashboards);
  };

  handleClick = event => {
    this.handleClose();
    switch (event.currentTarget.id) {
      case "dashboards":
        this.setState({ dashboardAnchorEl: event.currentTarget });
        break;
      case "maps":
        this.setState({ mapsAnchorEl: event.currentTarget });
        break;
      case "charts":
        this.setState({ chartsAnchorEl: event.currentTarget });
        break;
      case "reports":
        this.setState({ reportsAnchorEl: event.currentTarget });
        break;
      case "help":
        this.setState({ helpAnchorEl: event.currentTarget });
        break;
      case "account":
        this.setState({ accountAnchorEl: event.currentTarget });
        break;
    }
  };

  handleClose = dest => {
    this.setState({
      dashboardAnchorEl: null,
      mapsAnchorEl: null,
      chartsAnchorEl: null,
      reportsAnchorEl: null,
      helpAnchorEl: null,
      accountAnchorEl: null
    });
    if (dest && dest === window.location.pathname) {
      window.location.reload();
    }
  };

  handleToggleSettings = () => {
    this.props.toggleSettingsOpen();
  };

  render() {
    const { classes } = this.props;
    return (
      <div
        id="navMenu"
        className={classes.root + " flex-navMenu"}
        hidden={this.props.navBarHidden}
      >
        <AppBar className={classes.appBar} position="static">
          <Toolbar>
            {this.props.settingsEnabled && (
              <IconButton
                className="firewatch-settingsButton"
                edge="start"
                color="inherit"
                aria-label="Settings"
                onClick={this.handleToggleSettings}
              >
                <SettingsIcon />
              </IconButton>
            )}
            <div className={classes.title + " firewatch-logo"}>
              <ButtonBase
                focusRipple
                className={classes.logoButton}
                component={Link}
                to="/"
              >
                <img src="firewatchlogo.svg" style={styles.logo} />
              </ButtonBase>
            </div>
            {!this.props.userIsLoggedIn ? null : (
              <div>
                <IconButton
                  className="firewatch-navButton"
                  color="inherit"
                  component={Link}
                  to="/"
                >
                  <HomeIcon />
                </IconButton>
                <IconButton
                  className={classes.menuIconButton + " firewatch-navButton"}
                  color="inherit"
                  aria-controls="dashboards-menu"
                  onClick={this.handleClick}
                  title="Dashboards"
                  id="dashboards"
                >
                  <DashboardIcon />
                </IconButton>
                <Menu
                  id="dashboards-menu"
                  getContentAnchorEl={null}
                  anchorEl={this.state.dashboardAnchorEl}
                  keepMounted
                  open={Boolean(this.state.dashboardAnchorEl)}
                  onClose={this.handleClose}
                  className={classes.appBarMenu}
                  anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left"
                  }}
                >
                  {this.props.allDashboards &&
                    this.props.allDashboards.map((value, index) => (
                      <MenuItem
                        className={classes.condensedMenuItem}
                        component={Link}
                        key={index}
                        to={`/dashboard/${value.id}`}
                        onClick={() =>
                          this.handleClose(`/dashboard/${value.id}`)
                        }
                      >
                        {value.title}
                      </MenuItem>
                    ))}
                  {!this.props.allDashboards ||
                    (this.props.allDashboards.length == 0 && (
                      <MenuItem
                        className={classes.condensedMenuItem}
                        disabled={true}
                      >
                        No Dashboards Exist
                      </MenuItem>
                    ))}
                  {/*
                  <MenuItem
                    className={classes.condensedMenuItem}
                    component={Link}
                    to="/dashboard/create"
                    onClick={this.handleClose}
                  >
                    Create New Dashboard
                  </MenuItem>
                    */}
                </Menu>
                <IconButton
                  className={classes.menuIconButton + " firewatch-navButton"}
                  color="inherit"
                  aria-controls="maps-menu"
                  aria-label="Maps"
                  onClick={this.handleClick}
                  id="maps"
                  title="Maps"
                >
                  <MapIcon />
                </IconButton>
                <Menu
                  id="maps-menu"
                  getContentAnchorEl={null}
                  anchorEl={this.state.mapsAnchorEl}
                  keepMounted
                  open={Boolean(this.state.mapsAnchorEl)}
                  onClose={this.handleClose}
                  className={classes.appBarMenu}
                  anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left"
                  }}
                >
                  {Boolean(this.state.mapsAnchorEl) && (
                    <div>
                      <MenuItem
                        className={classes.condensedMenuHeader}
                        disabled={true}
                      >
                        Incidents
                      </MenuItem>
                      <MenuItem
                        className={classes.condensedMenuItem}
                        component={Link}
                        to="/map/type"
                        onClick={() => this.handleClose("/map/type")}
                      >
                        Type
                      </MenuItem>
                      <MenuItem
                        className={classes.condensedMenuItem}
                        component={Link}
                        to="/map/performance"
                        onClick={() => this.handleClose("/map/performance")}
                      >
                        Performance
                      </MenuItem>
                      <MenuItem
                        className={classes.condensedMenuItem}
                        component={Link}
                        to="/map/frequency"
                        onClick={() => this.handleClose("/map/frequency")}
                      >
                        Frequency
                      </MenuItem>
                      <MenuItem
                        className={classes.condensedMenuItem}
                        component={Link}
                        to="/map/heatmap"
                        onClick={() => this.handleClose("/map/heatmap")}
                      >
                        Heatmap
                      </MenuItem>
                    </div>
                  )}
                </Menu>
                <IconButton
                  className={classes.menuIconButton + " firewatch-navButton"}
                  color="inherit"
                  aria-controls="charts-menu"
                  title="Charts"
                  onClick={this.handleClick}
                  id="charts"
                >
                  <ChartIcon />
                </IconButton>
                <Menu
                  id="charts-menu"
                  getContentAnchorEl={null}
                  anchorEl={this.state.chartsAnchorEl}
                  keepMounted
                  open={Boolean(this.state.chartsAnchorEl)}
                  onClose={this.handleClose}
                  className={classes.appBarMenu}
                  anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                  // anchorReference="anchorPosition"
                  // anchorPosition={{top: this.state.chartsAnchorEl ? this.state.chartsAnchorEl.top : undefined, left: this.state.chartsAnchorEl ? this.state.chartsAnchorEl.left : undefined}}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left"
                  }}
                >
                  {" "}
                  {Boolean(this.state.chartsAnchorEl) && (
                    <div>
                      <MenuItem
                        className={classes.condensedMenuItem}
                        component={Link}
                        to="/chart/basic"
                        onClick={() => this.handleClose("/chart/basic")}
                      >
                        Basic
                      </MenuItem>
                      <MenuItem
                        className={classes.condensedMenuItem}
                        component={Link}
                        to="/chart/advanced"
                        onClick={() => this.handleClose("/chart/advanced")}
                      >
                        Advanced
                      </MenuItem>
                      <NestedMenuItem
                        className={classes.condensedMenuItem}
                        label="Accreditation"
                        parentMenuOpen={Boolean(this.state.chartsAnchorEl)}
                        onClick={this.handleItemClick}
                      >
                        <MenuItem
                          className={classes.condensedMenuItem}
                          component={Link}
                          to="/chart/basic/N4IgRghgzglgxgYQBYQE4BcAqBPADgUxAC4ACEATgAZ0kSAFBTAGRIQgBt2SAJCAOwAm7GHwDmIAL5A"
                          onClick={() =>
                            this.handleClose(
                              "/chart/basic/N4IgRghgzglgxgYQBYQE4BcAqBPADgUxAC4ACEATgAZ0kSAFBTAGRIQgBt2SAJCAOwAm7GHwDmIAL5A"
                            )
                          }
                        >
                          90% Call Handling
                        </MenuItem>
                        <MenuItem
                          className={classes.condensedMenuItem}
                          component={Link}
                          to="/chart/basic/N4IgRghgzglgxgYQBYQE4BcAqBPADgUxAC4ACEATgAZ0kSAFBTAGRMwFdUA7AezfVZgBbQgF8gA"
                          onClick={() =>
                            this.handleClose(
                              "/chart/basic/N4IgRghgzglgxgYQBYQE4BcAqBPADgUxAC4ACEATgAZ0kSAFBTAGRMwFdUA7AezfVZgBbQgF8gA"
                            )
                          }
                        >
                          90% Turnout Time
                        </MenuItem>
                        <MenuItem
                          className={classes.condensedMenuItem}
                          component={Link}
                          to="/chart/basic/N4IgRghgzglgxgYQBYQE4BcAqBPADgUxAC4ACEATgAZ0kSAFBTAGRM1QgDd8AbVmAW0IBfIA"
                          onClick={() =>
                            this.handleClose(
                              "/chart/basic/N4IgRghgzglgxgYQBYQE4BcAqBPADgUxAC4ACEATgAZ0kSAFBTAGRM1QgDd8AbVmAW0IBfIA"
                            )
                          }
                        >
                          90% Travel Time
                        </MenuItem>
                        <MenuItem
                          className={classes.condensedMenuItem}
                          component={Link}
                          to="/chart/basic/N4IgRghgzglgxgYQBYQE4BcAqBPADgUxAC4ACEATgAZ0kSAFBTAGRMwHt0IAbEgJXyi42AOyiEAvkA"
                          onClick={() =>
                            this.handleClose(
                              "/chart/basic/N4IgRghgzglgxgYQBYQE4BcAqBPADgUxAC4ACEATgAZ0kSAFBTAGRMwHt0IAbEgJXyi42AOyiEAvkA"
                            )
                          }
                        >
                          90% Total Response
                        </MenuItem>
                      </NestedMenuItem>
                      {/*
                  <MenuItem
                    className={classes.condensedMenuItem}
                    component={Link}
                    to="/chart/histogram"
                    onClick={this.handleClose}
                    disabled={true}
                  >
                    Histograms
                  </MenuItem>
                  */}
                    </div>
                  )}
                </Menu>

                <IconButton
                  focusRipple
                  className={classes.menuIconButton + " firewatch-navButton"}
                  color="inherit"
                  aria-controls="reports-menu"
                  title="Reports"
                  onClick={this.handleClick}
                  id="reports"
                >
                  <ListIcon />
                </IconButton>
                <Menu
                  id="reports-menu"
                  getContentAnchorEl={null}
                  anchorEl={this.state.reportsAnchorEl}
                  keepMounted
                  open={Boolean(this.state.reportsAnchorEl)}
                  onClose={this.handleClose}
                  className={classes.appBarMenu}
                  anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left"
                  }}
                >
                  <MenuItem
                    className={classes.condensedMenuItem}
                    component={Link}
                    to="/reports/incident"
                    onClick={this.handleClose}
                    disabled={false}
                  >
                    Incident Details
                  </MenuItem>
                  <MenuItem
                    className={classes.condensedMenuItem}
                    component={Link}
                    to="/reports/calldetails"
                    onClick={this.handleClose}
                    disabled={false}
                  >
                    Call Details
                  </MenuItem>
                  <MenuItem
                    className={classes.condensedMenuItem}
                    component={Link}
                    to="/reports/responses"
                    onClick={this.handleClose}
                    disabled={false}
                  >
                    Response Details
                  </MenuItem>
                  <MenuItem
                    className={classes.condensedMenuItem}
                    component={Link}
                    to="/reports/units"
                    onClick={this.handleClose}
                    disabled={false}
                  >
                    Unit Details
                  </MenuItem>
                </Menu>

                <IconButton
                  className={classes.menuIconButton + " firewatch-navButton"}
                  color="inherit"
                  title="Help"
                  aria-controls="help-menu"
                  onClick={this.handleClick}
                  id="help"
                >
                  <HelpIcon />
                </IconButton>
                <Menu
                  id="help-menu"
                  getContentAnchorEl={null}
                  anchorEl={this.state.helpAnchorEl}
                  keepMounted
                  open={Boolean(this.state.helpAnchorEl)}
                  onClose={this.handleClose}
                  className={classes.appBarMenu}
                  anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left"
                  }}
                >
                  <MenuItem
                    className={classes.condensedMenuItem}
                    component={Link}
                    to="/help/creatingDashboards"
                    onClick={this.handleClose}
                    disabled={true}
                  >
                    Creating Dashboards
                  </MenuItem>
                  <hr style={styles.menuSeparator} />
                  <MenuItem
                    className={classes.condensedMenuItem}
                    component={Link}
                    to="/help/about"
                    onClick={this.handleClose}
                  >
                    About Code3 Firewatch
                  </MenuItem>
                </Menu>
                <IconButton
                  className={classes.menuIconButton + " firewatch-navButton"}
                  color="inherit"
                  title="Account"
                  aria-controls="account-menu"
                  onClick={this.handleClick}
                  id="account"
                >
                  <AccountIcon />
                </IconButton>
                <Menu
                  id="account-menu"
                  getContentAnchorEl={null}
                  anchorEl={this.state.accountAnchorEl}
                  keepMounted
                  open={Boolean(this.state.accountAnchorEl)}
                  onClose={this.handleClose}
                  className={classes.appBarMenu}
                  anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left"
                  }}
                >
                  {Boolean(this.state.accountAnchorEl) && (
                    <div>
                      <MenuItem
                        className={classes.condensedMenuHeader}
                        disabled={true}
                      >
                        {api.getUserName()}
                      </MenuItem>
                      <MenuItem
                        className={classes.condensedMenuItem}
                        component={Link}
                        to="/settings/user"
                        onClick={this.handleClose}
                      >
                        Settings
                      </MenuItem>
                      <MenuItem
                        className={classes.condensedMenuItem}
                        component={Link}
                        to="/logout"
                        onClick={this.handleClose}
                      >
                        Logout
                      </MenuItem>
                      <hr style={styles.menuSeparator} />
                      {(currentUserHasRole("SysAdmin") ||
                        currentUserHasRole("Admin")) && (
                        <>
                          <MenuItem
                            className={classes.condensedMenuHeader}
                            disabled={true}
                          >
                            {api.getJurisdictionName()}
                          </MenuItem>
                          <MenuItem
                            className={classes.condensedMenuItem}
                            component={Link}
                            to="/settings/jurisdiction"
                            onClick={this.handleClose}
                          >
                            Settings
                          </MenuItem>
                          <MenuItem
                            className={classes.condensedMenuItem}
                            component={Link}
                            to="/settings/users"
                            onClick={this.handleClose}
                          >
                            Users
                          </MenuItem>
                          <MenuItem
                            className={classes.condensedMenuItem}
                            component={Link}
                            to="/settings/data"
                            onClick={this.handleClose}
                          >
                            Data
                          </MenuItem>
                        </>
                      )}
                    </div>
                  )}
                </Menu>
              </div>
            )}
          </Toolbar>
        </AppBar>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const settingsEnabled = getSettingsEnabled(state);
  const allDashboards = getAllDashboards(state);
  const navBarHidden = getNavbarHidden(state);
  return { settingsEnabled, allDashboards, navBarHidden };
};

export default withStyles(useStyles)(
  connect(mapStateToProps, {
    toggleSettingsOpen,
    setSettingsEnabled,
    setAllDashboards
  })(NavMenu)
);
